// Modules
import React, { Component } from 'react';
import { isNil, replace, isEmpty, isNaN } from 'lodash';
import i18n from 'i18n-react';
import { Helmet } from 'react-helmet';

// Styling
import './Products.css';
import './Pagination.scss';

// Assets
import sale from '../../../assets/sale.svg';
import ASK_FOR_IMAGE from '../../../assets/image-coming-soon.svg';
import LOADING_IMAGE from '../../../assets/loadingImage_1.svg';

// Utils
import { makeThumbnailSource } from '../../../@shared/components/Carousel';
import { loadingProducts } from '../constants';

// Components
import { LinkComponent } from '../../../@shared/components/LinkComponent';
import { getCanonicalUrl } from '../../../core/utils';

export const idToRoute = {
    //seating
    28: '/seating',
    30: '/seating/lounge-chairs',
    29: '/seating/dining-chairs',
    32: '/seating/office-chairs',
    31: '/seating/sofas',
    33: '/seating/stools-and-ottomans',
    34: '/seating/beds-and-daybeds',
    //tables
    42: '/tables',
    44: '/tables/coffee-tables',
    45: '/tables/desks',
    43: '/tables/dining-tables',
    46: '/tables/nesting-tables',
    47: '/tables/side-tables',
    48: '/tables/trolleys-and-bar-carts',
    //storage
    35: '/storage',
    38: '/storage/shelf-and-wall-systems',
    39: '/storage/chests-of-drawers',
    36: '/storage/sideboards',
    37: '/storage/highboards',
    40: '/storage/nightstands',
    41: '/storage/wardrobes',
    //lighting
    23: '/lighting',
    24: '/lighting/ceiling-and-pendant-lights',
    26: '/lighting/desk-and-table-lights',
    25: '/lighting/floor-lights',
    27: '/lighting/wall-lights-and-sconces',
    //accessories
    16: '/other',
    869: '/other/outdoor',
    18: '/other/coatracks',
    19: '/other/kids',
    20: '/other/magazine-stands',
    22: '/other/miscellaneous',
    92: '/other/mirrors',
};

export const routeToId = {
    //seating
    '/seating/lounge-chairs': 30,
    '/seating/dining-chairs': 29,
    '/seating/office-chairs': 32,
    '/seating/sofas': 31,
    '/seating/stools-and-ottomans': 33,
    '/seating/beds-and-daybeds': 34,
    '/seating': 28,
    //tables
    '/tables': 42,
    '/tables/coffee-tables': 44,
    '/tables/desks': 45,
    '/tables/dining-tables': 43,
    '/tables/nesting-tables': 46,
    '/tables/side-tables': 47,
    '/tables/trolleys-and-bar-carts': 48,
    //storage
    '/storage': 35,
    '/storage/shelf-and-wall-systems': 38,
    '/storage/chests-of-drawers': 39,
    '/storage/sideboards': 36,
    '/storage/highboards': 37,
    '/storage/nightstands': 40,
    '/storage/wardrobes': 41,
    //lighting
    '/lighting': 23,
    '/lighting/ceiling-and-pendant-lights': 24,
    '/lighting/desk-and-table-lights': 26,
    '/lighting/floor-lights': 25,
    '/lighting/wall-lights-and-sconces': 27,
    //accessories
    '/other': 16,
    '/other/outdoor': 869,
    '/other/coatracks': 18,
    '/other/kids': 19,
    '/other/magazine-stands': 20,
    '/other/miscellaneous': 22,
    '/other/mirrors': 92,
};

export function getUrlFromCategoryId(id, slug) {
    return idToRoute[id] + '/' + slug;
}
const renderBreadCrumbUrl = string => replace(string, /-/g, ' ');

// IN product --> OUT url
export function getProductUrl(product) {
    if (!product) return '/';
    const { categories, slug } = product;
    if (!categories || !slug) return '/';

    const itemCategoryIds = categories.map(item => item.id);
    const categoryIds = [28, 42, 35, 23, 16]; // Hoofdcategorieën
    const categoryIdForUrl = itemCategoryIds.find(id => !categoryIds.includes(id));
    return getUrlFromCategoryId(categoryIdForUrl, slug);
}

export class ImageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            error: false,
        };
    }

    onError = () => {
        this.setState({
            src: ASK_FOR_IMAGE,
            error: true,
        });
    };

    render() {
        const { src, error } = this.state;
        const { src: srcFromProps, alt, style, height } = this.props;

        return <img src={error ? src : srcFromProps} onError={this.onError} alt={alt} style={style} height={height} loading={'lazy'} />;
    }
}

// Component for rendering the product-tile
export const ProductTile = ({ item, category, onNavigate }) => {
    const url = getProductUrl(item);

    const priceType = item.attributes.find(item => item.name === 'price_per');
    const priceTypeLabel = priceType ? priceType.options[0] : '';
    const isSold = item.stock_status === 'outofstock';
    const isOnHold = !isNil(item.attributes.find(item => item.name === 'on_hold'));
    const isOnRequest = !isNil(item.attributes.find(item => item.name === 'on_request'));
    const isOnSale = item.on_sale;

    return (
        <div className="product-tile">
            <LinkComponent url={url} onClick={() => onNavigate({ url, categoryId: category.id })}>
                {item.on_sale && (
                    <div className="on-sale-button">
                        <img src={sale} alt={'on sale'} />
                    </div>
                )}
                <ImageComponent
                    src={item.images === 'loading' ? LOADING_IMAGE : item.images[0] ? makeThumbnailSource(item.images[0].src) : ASK_FOR_IMAGE}
                    style={{ width: '100%' }}
                    alt={item.name}
                />

                <div className="product-tile-fill">
                    <p>{item.name}</p>

                    {isSold && (
                        <div className="price-label sold">
                            <span>SOLD OUT</span>
                        </div>
                    )}

                    {isOnHold && !isSold && (
                        <div className="price-label sold">
                            <span>ON HOLD</span>
                        </div>
                    )}

                    {isOnRequest && !isOnHold && !isSold && (
                        <div className="price-label sold">
                            <span>PRICE ON REQUEST</span>
                        </div>
                    )}

                    {!isOnRequest && !isOnHold && !isSold && (
                        <div className="price-label">
                            {isOnSale && <span className="old-price">€{item.regular_price},-</span>}
                            {item.price !== 'loading' && <span>€{item.price},-</span>}
                            <span className={'price-type-label'}>{i18n.translate(priceTypeLabel)}</span>
                        </div>
                    )}
                </div>
            </LinkComponent>
        </div>
    );
};

class Products extends Component {
    // Vraag uit de URL de huidige pagina op
    getCurrentPage = () => {
        const { location } = this.props;
        const pageFromUrl = location.search;
        const pageNumber = parseInt(pageFromUrl.split('page=')[1]);
        return !isNaN(pageNumber) ? pageNumber : 1;
    };

    componentDidMount() {
        const { match, location } = this.props;
        if (location.pathname === '/search') {
            this.props.getProducts({ url: `${location.pathname}${location.search}`, search: `${this.renderSearchResults(location.search)}` });
            return;
        }
        const page = this.getCurrentPage();
        this.props.getProducts({ url: match.url, categoryId: routeToId[match.path], page: page });
    }

    renderSearchResults = searchString => {
        return searchString.replace('?result=', '');
    };

    render() {
        const { productsLoading, onNavigate, match } = this.props;

        const {
            match: { path },
            location: locationProp,
            products,
            historyObject,
        } = this.props;

        const category = path.split('/')[1];
        const subcategory = path.split('/')[2];
        const productTiles = productsLoading ? loadingProducts : products;

        const itemOffered = productsLoading ? [] : products.filter(product => !isEmpty(product.price));

        const isSearchPage = locationProp.pathname === '/search';

        const helmetContentsImage = {
            new: 'https://shop.zogoedalsoud.com/sdImages/just-arrived-vintage-design-pieces-for-sale-at-zo-goed-als-oud-furniture-lighting-and-accessories-shop.jpg',
            sold: 'https://shop.zogoedalsoud.com/sdImages/shop-all-our-vintage-design-furniture-lighting-and-accessories-online-at-zo-goed-als-oud.jpg',
            sale: 'https://shop.zogoedalsoud.com/sdImages/design-furniture-lighting-and-accessories-on-sale-at-zo-goed-als-oud-online-shop-for-20th-century-furniture.jpg',
            all: 'https://shop.zogoedalsoud.com/sdImages/shop-all-our-vintage-design-furniture-lighting-and-accessories-online-at-zo-goed-als-oud.jpg',

            seating:
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-sofas-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            tables:
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-coffee-tables-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            storage:
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-sideboards-and-credenzas-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            lighting:
                'https://shop.zogoedalsoud.com/sdImages/lighting/buy-vintage-design-floor-lights-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            other:
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-mirrors-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',

            //seating
            'lounge-chairs':
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-lounge-chairs-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'dining-chairs':
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-dining-chairs-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'office-chairs':
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-office-chairs-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            sofas:
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-sofas-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'stools-and-ottomans':
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-stools-and-ottomans-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'beds-and-daybeds':
                'https://shop.zogoedalsoud.com/sdImages/seating/buy-vintage-design-daybeds-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',

            //tables
            'coffee-tables':
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-coffee-tables-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            desks:
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-desks-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'dining-tables':
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-dining-tables-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'nesting-tables':
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-mimisets-and-nesting-tables-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'side-tables':
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-side-tables-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'trolleys-and-bar-carts':
                'https://shop.zogoedalsoud.com/sdImages/tables/buy-vintage-design-trolleys-and-bar-carts-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',

            //storage
            'shelf-and-wall-systems':
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-shelving-and-wall-systems-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'chests-of-drawers':
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-chest-of-drawers-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            sideboards:
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-sideboards-and-credenzas-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            highboards:
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-highboards-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            nightstands:
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-night-stands-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            wardrobes:
                'https://shop.zogoedalsoud.com/sdImages/storage/buy-vintage-design-wardrobes-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',

            //lighting
            'ceiling-and-pendant-lights':
                'https://shop.zogoedalsoud.com/sdImages/lighting/buy-vintage-design-pendant-lights-and-chandeliers-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'desk-and-table-lights':
                'https://shop.zogoedalsoud.com/sdImages/lighting/buy-vintage-design-table-and-desk-lights-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'floor-lights':
                'https://shop.zogoedalsoud.com/sdImages/lighting/buy-vintage-design-floor-lights-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'wall-lights-and-sconces':
                'https://shop.zogoedalsoud.com/sdImages/lighting/buy-vintage-design-wall-lights-and-sconces-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',

            //accessories
            outdoor:
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-art-and-sculptures-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            coatracks:
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-coatracks-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            kids:
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-kids-furniture-and-toys-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            'magazine-stands':
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-magazine-stands-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            miscellaneous:
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-games-and-other-items-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
            mirrors:
                'https://shop.zogoedalsoud.com/sdImages/accessories/buy-vintage-design-mirrors-from-the-20th-century-online-at-zo-goed-als-oud-furniture-lighting-and-accesories-shop.jpg',
        };

        const helmetContentsTitle = {
            new: 'New | Just Arrived Vintage Design Items at Zo Goed Als Oud',
            sold: 'Sold | Vintage Design Items Sold by Zo Goed Als Oud',
            sale: 'ON SALE | Discounted Vintage Design Items at Zo Goed Als Oud',
            all: 'All | Shop The Vintage Design Collection at Zo Goed Als Oud',
            search: 'Search results for',

            seating: 'Seating | Buy Vintage Design Furniture at Zo Goed Als Oud',
            tables: 'Tables | Buy Vintage Design Furniture at Zo Goed Als Oud',
            storage: 'Storage | Buy Vintage Design Furniture at Zo Goed Als Oud',
            lighting: 'Lighting | Buy Vintage Design Lighting at Zo Goed Als Oud',
            other: 'Accessories | Buy Vintage Design Items at Zo Goed Als Oud',

            //seating
            'lounge-chairs': 'Lounge Chairs | Buy Vintage Design Seating at Zo Goed Als Oud',
            'dining-chairs': 'Dining Chairs | Buy Vintage Design Seating at Zo Goed Als Oud',
            'office-chairs': 'Office Chairs | Buy Vintage Design Seating at Zo Goed Als Oud',
            sofas: 'Sofas | Buy Vintage Design Seating at Zo Goed Als Oud',
            'stools-and-ottomans': 'Stools and Ottomans | Buy Vintage Design Seating at Zo Goed Als Oud',
            'beds-and-daybeds': 'Beds and Daybeds | Buy Vintage Design Seating at Zo Goed Als Oud',

            //tables
            'coffee-tables': 'Coffee Tables | Buy Vintage Design Tables at Zo Goed Als Oud',
            desks: 'Desks | Buy Vintage Design Tables at Zo Goed Als Oud',
            'dining-tables': 'Dining Tables | Buy Vintage Design Tables at Zo Goed Als Oud',
            'nesting-tables': 'Nesting Tables | Buy Vintage Design Tables at Zo Goed Als Oud',
            'side-tables': 'Side Tables | Buy Vintage Design Tables at Zo Goed Als Oud',
            'trolleys-and-bar-carts': 'Trolleys and Bar Carts | Buy Vintage Design Tables at Zo Goed Als Oud',

            //storage
            'shelf-and-wall-systems': 'Shelf & Wall Systems | Buy Vintage Design Storage at Zo Goed Als Oud',
            'chests-of-drawers': 'Chest of Drawers | Buy Vintage Design Storage at Zo Goed Als Oud',
            sideboards: 'Sideboards | Buy Vintage Design Storage at Zo Goed Als Oud',
            highboards: 'Highboards | Buy Vintage Design Storage at Zo Goed Als Oud',
            nightstands: 'Nightstands | Buy Vintage Design Storage at Zo Goed Als Oud',
            wardrobes: 'Wardrobes | Buy Vintage Design Storage at Zo Goed Als Oud',

            //lighting
            'ceiling-and-pendant-lights': 'Ceiling and Pendant Lights | Buy Vintage Design Lighting at Zo Goed Als Oud',
            'desk-and-table-lights': 'Desk and Table Lights | Buy Vintage Design Lighting at Zo Goed Als Oud',
            'floor-lights': 'Floor Lights | Buy Vintage Design Lighting at Zo Goed Als Oud',
            'wall-lights-and-sconces': 'Wall Lights and Sconces | Buy Vintage Design Lighting at Zo Goed Als Oud',

            //accessories
            outdoor: 'Outdoor | Buy 20th Century Outdoor pieces Online',
            coatracks: 'Coatracks | Buy Vintage Design Accessories at Zo Goed Als Oud',
            kids: 'Kids | Buy Vintage Design Accessories at Zo Goed Als Oud',
            'magazine-stands': 'Magazine Stands | Buy Vintage Design Accessories at Zo Goed Als Oud',
            miscellaneous: 'Miscellaneous | Buy Vintage Design at Zo Goed Als Oud',
            mirrors: 'Mirrors | Buy Vintage Design Accessories at Zo Goed Als Oud',
        };

        const helmetContentsDescription = {
            new: 'See the newest vintage design items at Zo Goed Als Oud for sale. Refurbished when necessary and ready for global shipment.',
            sold: 'Many of our items at Zo Goed Als Oud already found happy owners all over the world. All Packed and shipped securely.',
            sale: 'Our selected vintage design pieces currently on sale at Zo Goed Als Oud. Global shipping available on all pieces.',
            all: 'We have a large, ever changing and carefully picked collection of vintage furniture, lighting and accessories at Zo Goed Als Oud available for sale.',
            search: 'Search our collection of vintage design furniture, lighting and accessories',

            seating: 'Buy vintage design seating online. Shop sofas, dining chairs, daybeds, lounge chairs and more at Zo Goed Als Oud. Global shipping available.',
            tables: 'Buy vintage design tables online. Shop dining tables, desks, coffee tables, nesting tables and more at Zo Goed Als Oud. Global shipping available.',
            storage: 'Buy vintage design storage online. Shop sideboards, highboards, wall systems, wardrobes and more at Zo Goed Als Oud. Global shipping available.',
            lighting: 'Buy vintage design lighting online. Shop pendants, desk lights, wall lights, floor lamps and more at Zo Goed Als Oud. Global shipping available.',
            other: 'Buy vintage design accessories online. Shop magazine stands, art, mirrors, rugs and more at Zo Goed Als Oud. Global shipping available.',

            //seating
            'lounge-chairs': 'Buy vintage design lounge chairs. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'dining-chairs': 'Buy vintage design dining chairs. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'office-chairs': 'Buy vintage design office chairs. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            sofas: 'Buy vintage design sofas. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'stools-and-ottomans':
                'Buy vintage design stools and ottomans. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'beds-and-daybeds': 'Buy vintage design bed and daybeds. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',

            //tables
            'coffee-tables': 'Buy vintage design coffee tables. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            desks: 'Buy vintage design desks. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'dining-tables': 'Buy vintage design dining tables. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'nesting-tables': 'Buy vintage design nesting tables. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'side-tables': 'Buy vintage design side tables. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'trolleys-and-bar-carts':
                'Buy vintage design trolleys and bar carts. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            //storage

            'shelf-and-wall-systems': 'Buy vintage design wall systems. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'chests-of-drawers': 'Buy vintage design chest of drawers. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            sideboards: 'Buy vintage design sideboards. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            highboards: 'Buy vintage design highboards. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            nightstands: 'Buy vintage design nightstands. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            wardrobes: 'Buy vintage design wardrobes. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',

            //lighting
            'ceiling-and-pendant-lights':
                'Buy vintage design ceiling and pendant lights. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'desk-and-table-lights':
                'Buy vintage design desk and table lights. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'floor-lights': 'Buy vintage design floor lights. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            'wall-lights-and-sconces':
                'Buy vintage design wall lights and sconces. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',

            //accessories
            outdoor: 'Buy 20th century outdoor pieces online at Zo Goed Als Oud. Global shipping available.',
            coatracks: 'Buy vintage design coatracks. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            kids: 'Buy vintage kids items. Shop toys, childrens chairs and desks, games and more at Zo Goed Als Oud.',
            'magazine-stands': 'Buy vintage design magazine stands. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
            miscellaneous: 'Shop 20th century vintage items online at Zo Goed Als Oud.',
            mirrors: 'Buy vintage design mirrors. Shop our collection with many pieces by famous designers from the 20th century. Global shipping available.',
        };
        // @todo Hier zijn ze echt Erwin!
        const mobilePageTitleCategorie = {
            new: 'Just arrived pieces',
            sold: 'The pieces we sold over the time',
            sale: 'Shop our pieces currently on sale', //Shop the Winter Sale and save up to 35%
            all: 'All our pieces for sale',

            seating: 'Shop our seating collection',
            tables: 'Shop our table collection',
            storage: 'Shop our storage collection',
            lighting: 'Shop our lighting collection',
            other: 'Shop our collection of accessories',

            //seating
            'lounge-chairs': 'Shop our lounge chairs',
            'dining-chairs': 'Shop our dining chairs',
            'office-chairs': 'Shop our office chairs',
            sofas: "Shop our sofa's",
            'stools-and-ottomans': 'Shop our stools and ottomans',
            'beds-and-daybeds': 'Shop our beds and daybeds',

            //tables
            'coffee-tables': 'Shop our coffee tables',
            desks: 'Shop our desks',
            'dining-tables': 'Shop our dining tables',
            'nesting-tables': 'Shop our nesting tables',
            'side-tables': 'Shop our side tables',
            'trolleys-and-bar-carts': 'Shop our trolleys and bar carts',
            //storage

            'shelf-and-wall-systems': 'Shop our shelving and wall systems',
            'chests-of-drawers': 'Shop our chests of drawers',
            sideboards: 'Shop our sideboards',
            highboards: 'Shop our highboards',
            nightstands: 'Shop our nightstands',
            wardrobes: 'Shop our wardrobes',

            //lighting
            'ceiling-and-pendant-lights': 'Shop our ceiling and pendant lights',
            'desk-and-table-lights': 'Shop our desk and table lights',
            'floor-lights': 'Shop our floor lights',
            'wall-lights-and-sconces': 'Shop our wall lights and sconces',

            //accessories
            outdoor: 'Shop our outdoor collection',
            coatracks: 'Shop our coatracks',
            kids: 'Shop our collection for kids',
            'magazine-stands': 'Shop our magazine stands',
            miscellaneous: 'Shop our other pieces',
            mirrors: 'Shop our mirrors',
        };

        const helmetCategory = subcategory || category;

        const itemBreadcrumbList = [
            { '@type': 'ListItem', position: 1, item: { '@type': 'Thing', name: 'Home', '@id': 'https://zogoedalsoud.com' } },
            { '@type': 'ListItem', position: 2, item: { '@type': 'Thing', name: renderBreadCrumbUrl(category), '@id': `https://zogoedalsoud.com/${category}` } },
        ];

        if (subcategory) {
            itemBreadcrumbList.push({ '@type': 'ListItem', position: 3, item: { '@type': 'Thing', name: renderBreadCrumbUrl(subcategory), '@id': window.location.href } });
        }

        const structuredDataProducts = [
            {
                '@context': 'http://schema.org/',
                '@type': 'WebPage',
                name: helmetCategory, //Naam van de van de category
                description: helmetContentsDescription[helmetCategory],
                image: helmetContentsImage[helmetCategory],
                url: window.location.href, //Url van de categorypagina + pagination
                mainEntity: {
                    '@type': 'WebPageElement',
                    offers: {
                        '@type': 'offer',
                        itemOffered: itemOffered.map(product => {
                            return {
                                '@type': 'product',
                                name: product.name || '',
                                url: getProductUrl(product),
                                image: product.images[0] ? product.images[0].src : LOADING_IMAGE,
                                offers: {
                                    '@type': 'offer',
                                    price: product.price,
                                    priceCurrency: 'EUR',
                                },
                            };
                        }),
                    },
                },
            },

            {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: itemBreadcrumbList,
            },
        ];

        // pagination variables:
        const currentPage = this.getCurrentPage();
        const nextPage = match.url + '?page=' + (currentPage + 1);
        const previousPage = currentPage - 1 === 1 ? match.url : match.url + '?page=' + (currentPage - 1);
        const numberOfItemsOnThisPage = products.length;

        const canonicalUrl = getCanonicalUrl();

        return (
            <>
                <div className={'home-intro mobile-only'}>{mobilePageTitleCategorie[helmetCategory]}</div>
                {category === 'search' && (
                    <div className={'search-results'}>
                        <h5>Your search results for "{this.renderSearchResults(decodeURI(locationProp.search))}"</h5>
                        {isEmpty(products) && <p>{productsLoading ? 'Searching for products...' : 'Sorry, we could not find that special item you were looking for...'}</p>}
                    </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {isSearchPage ? (
                        <Helmet>
                            <title>{helmetContentsTitle['search']} "{this.renderSearchResults(decodeURI(locationProp.search))}"</title>
                            <meta name="description" content={helmetContentsDescription['search']} />

                        </Helmet>
                    ) : (
                        <Helmet>
                            <title>{helmetContentsTitle[helmetCategory]}</title>
                            {canonicalUrl && <link rel={'canonical'} href={canonicalUrl} />}
                            <meta name="description" content={helmetContentsDescription[helmetCategory]} />

                            <meta property="og:title" content={helmetContentsTitle[helmetCategory]} />
                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={window.location.href} />
                            <meta property="og:image" content={helmetContentsImage[helmetCategory]} />
                            <meta property="og:image:alt" content={helmetContentsTitle[helmetCategory]} />
                            <meta property="og:site_name" content="Zo Goed Als Oud" />
                            <meta property="og:description" content={helmetContentsDescription[helmetCategory]} />

                            <meta name="twitter:card" content="summary" />
                            <meta name="twitter:title" content={helmetContentsTitle[helmetCategory]} />
                            <meta name="twitter:description" content={helmetContentsDescription[helmetCategory]} />
                            <meta name="twitter:url" content={window.location.href} />
                            <meta name="twitter:image" content={helmetContentsImage[helmetCategory]} />
                            <meta name="twitter:image:alt" content={helmetContentsTitle[helmetCategory]} />

                            <script type="application/ld+json">{JSON.stringify(structuredDataProducts)}</script>
                        </Helmet>
                    )}

                    <div className="product-tiles">
                        {/*<ProductFilter />*/}

                        {productTiles.map((item, index) => (
                            <ProductTile key={index} item={item} category={category} onNavigate={productsLoading ? () => {} : onNavigate} />
                        ))}
                    </div>
                </div>

                {category !== 'new' && category !== 'search' && (
                    <div className={'new-pagination'}>
                        {currentPage > 1 && (
                            <LinkComponent
                                url={previousPage}
                                onClick={() => {
                                    historyObject.push(previousPage);
                                }}
                            >
                                <i className={'fa fa-caret-left'} aria-label={'Previous page'} />
                            </LinkComponent>
                        )}

                        <LinkComponent className={'current-page'} url={'#'}>
                            {currentPage}
                        </LinkComponent>

                        {numberOfItemsOnThisPage === 24 && (
                            <LinkComponent
                                url={nextPage}
                                onClick={() => {
                                    historyObject.push(nextPage);
                                }}
                            >
                                <i className={'fa fa-caret-right'} aria-label={'Next page'} />
                            </LinkComponent>
                        )}
                    </div>
                )}
            </>
        );
    }
}

export default Products;
