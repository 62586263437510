// Modules
import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import T from 'i18n-react';
import ReactGA from 'react-ga';

// Store
import { store } from './services/store.js';

// Components
import ModalContainer from './core/modal/ModalContainer';
import ScrollToTop from './@shared/components/ScrollToTop';

// Styling Library Imports:
import 'bootstrap/dist/css/bootstrap.css';

// Translations
import en from './@shared/translations/en';
import Container from './Container';
import CookieBanner from './@shared/components/CookieBanner';


const App = () => {
    const defaultLanguage = en;
    T.setTexts(defaultLanguage);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Fragment>
                    <ScrollToTop>
                        <div>
                            <Container />
                        </div>
                    </ScrollToTop>
                    <ModalContainer />
                    <CookieBanner
                        accept={() => {

                            // Google Analytics
                            const trackingId = 'UA-156355587-1';
                            ReactGA.initialize(trackingId);
                            ReactGA.set({
                                userId: '1',
                                page: window.location.pathname,
                            });
                            ReactGA.pageview(window.location.pathname);

                            console.log('Google analytics initialized', window.location.pathname);

                        }}
                    />
                </Fragment>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
