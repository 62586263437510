import { fetchApiGET } from '../../../services/api/apiSagas';
import { call, put, takeEvery } from 'redux-saga/effects';
import { isUndefined, isEmpty } from 'lodash';

import {
    GET_DESIGNERS,
    GET_FEATURED_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCTS_BY_DESIGNER,
    GET_RELATED_PRODUCTS,
    GET_SHOP_LATEST_PRODUCTS,
} from '../../../services/api/constants';
import { getRelatedProducts } from './actionCreators';

// Call the main saga for fetching data -->
function* fetchDataFunc(action) {
    const { payload, type } = action;
    const result = yield call(fetchApiGET, transformer(payload));

    // Check if we need to reload some Related items:
    if (!isUndefined(result)) {
        // If we were a Product action, Try to load the related products:
        if (type === GET_PRODUCT) {

            if (isEmpty(result.result.data)) {
                // Redirect to not found page
                window.location.href = '/404';
            } else {
                const ids = result.result.data[0].related_ids;
                if (!isUndefined(ids)) {
                    yield put(getRelatedProducts(ids));
                }
            }
        }
    }
}

export function* getProductsSaga() {
    yield takeEvery(GET_PRODUCTS, fetchDataFunc);
}

export function* getDesignersSaga() {
    yield takeEvery(GET_DESIGNERS, fetchDataFunc);
}

export function* getFeaturedProductsSaga() {
    yield takeEvery(GET_FEATURED_PRODUCTS, fetchDataFunc);
}

export function* getShopLatestProductsSaga() {
    yield takeEvery(GET_SHOP_LATEST_PRODUCTS, fetchDataFunc);
}

export function* getProductSaga() {
    yield takeEvery(GET_PRODUCT, fetchDataFunc);
}

export function* getRelatedProductsSaga() {
    yield takeEvery(GET_RELATED_PRODUCTS, fetchDataFunc);
}

export function* getProductsByDesignerSaga() {
    yield takeEvery(GET_PRODUCTS_BY_DESIGNER, fetchDataFunc);
}

/**
 * Transformer
 * @param payload
 * @returns {{dataType: string, url: string}}
 */
const transformer = ({ dataType, url, page }) => ({
    page,
    dataType,
    url: 'https://shop.zogoedalsoud.com/wp-json' + url,
});
