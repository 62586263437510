// Modules
import React from 'react';
import cx from 'classnames';
import i18n from 'i18n-react';
import { Helmet } from 'react-helmet';
import { includes } from 'lodash';

// Constants
import { cserviceMenuItems } from '../constants';

// Styling
import './CustomerService.css';
import './CustomerService.scss';

// Assets --volgorde klopt niet, bekijk filenames voor de juiste--
import about from '../../../assets/info-page/about-zo-goed-als-oud-vintage-design-furniture-online.jpg';
import items from '../../../assets/info-page/buy-our-vintage-design-items-online-at-zo-goed-als-oud-com.jpg';
import payments from '../../../assets/info-page/order-vintage-design-items-online-at-our-website-and-buy-secure-from-zo-goed-als-oud.jpg';
import visiting from '../../../assets/info-page/secure-packaging-and-world-wide-shipping-of-vintage-design-furniture-at-zo-goed-als-oud.jpg';
import shipping from '../../../assets/info-page/payments-made-safely-when-buying-vintage-design-furniture-lighting-and-accessories-at-zo-goed-als-oud.jpg';
import ordering from '../../../assets/info-page/vistit-our-store-near-amsterdam-and-schiphol-and-come-see-our-collection-of-vintage-design-furniture-at-zo-goed-als-oud.jpg';
import {getCanonicalUrl} from '../../../core/utils';


class CustomerService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 0, // desktop
            activeItems: [], //mobile
        };
    }

    onMenuClick = activeItem => {
        this.setState({ activeItem });
    };

    render() {
        const {
            onMenuClick,
            state: { activeItem, activeItems },
        } = this;

        const imageByIndex = [about, items, ordering, shipping, payments, visiting];

        const prefix = cserviceMenuItems[activeItem];

        const structuredDataCustomerInfo =                             
            [{
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "name": "Customer info",
                "image": "https://shop.zogoedalsoud.com/sdImages/customer-info-ask-about-shipping-payments-ordering-visiting-and-who-we-are-at-zo-goed-als-oud-vintage-design-shop-online.jpg",
                "url": window.location.href,
                "description": "Customer info for Zo Goed Als Oud. Find your answers for shipping, payments, ordering, vistiting and who we are.",
                "mainEntity": [
                {
                    "@type": "Question",
                    "name": "About us",
                    "answerCount": "1",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Zo Goed Als Oud Zo Goed Als Oud, meaning “As Good As Old” in Dutch, was founded in 2011 to meet the need and demand for furniture, lighting and accessories from the 20th century. The founder was Fleur Bannink, then already a large collector of items from the 20th century. She graduated from the Artemis Design Academy in 2009, where she learned to combine these items with contemporary design. More and more often the question arose whether the items she collected and used for projects were also for sale. Apparently more people were charmed by the timeless design of these items. This need and demand became what is now Zo Goed Als Oud. An online and local store for items from the 20th century. Refurbished when necessary and ready for global shipping. Our store. Our store consists of a large storage filled with chairs, couches, tables, lamps, dressers, vases, artworks and more. Everything is from the 20th century and most of well-known designers. A true treasure house for every design enthusiast. We do everything ourselves. We first search our network for new and beautiful items. We then fix them up when necessary in our own workshop. There they are re-upholstered with the right fabric or they receive a new layer of lacquer or repair. When that is done, they are photographed and presented to the world through our website. As a result, we know everything about the items that we sell. And we think this is important, because we love them as much as you do"
                    }
                },
                {
                    "@type": "Question",
                    "name": "Our items",
                    "answerCount": "1",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our items. Zo Goed Als Oud offers items for sale through her website or local store in Oude Meer. The items we offer are furniture, lighting and accessories from the 20th century. Most of those items are from recognized designers and highly collectable. We try to describe the condition of these items as clear as possible on our website. Item condition. Our Items are always thoroughly inspected and sometimes restored with new upholstery, new paint, new veneer, repairs etcetera when neccessary. This will be described in the item description on the item page and is always with respect to the designer of the piece. Please note that all of our items are vintage (not new) and can have some wear. For example, some of our items are over 60 years old."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Ordering",
                    "answerCount": "1",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "How to order. Items can be ordered trough our website or purchased in our local store. Because we sell vintage items with history and character, which can have wear, we would like to have some personal contact after ordering. That is why there is no paying or checkout option on our website. Items can be ordered by filling in the order item form on a product page or by calling us from 9 AM till 5 PM CEST. In this way we can deliver you the best possible care and details about an item you would like to buy. Please contact us if you would like to order multiple products in one order or use the favorites function on the website. It is also possible to see, inspect and buy the items in our local store in Oude Meer, the Netherlands. Please note that we are open by appointment only. Returning. By European law, customers of The European Union have the right of withdrawal within 14 days after the day they received an item bought from a webshop. If you would like to return an item, please contact us within the 14 days after the day you received the item. Please note that the shipping costs for returning are always at buyers expense. Also, the item has to be returned in the same condition as it was received and must be carefully packed for transport. Returning is on full responsibility of the buyer."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Shipping",
                    "answerCount": "1",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Global shipping. If an item is ordered, the shipment is sent as soon as possible (1-5 days) after receipt of payment in our bank account. It is possible to sent the shipment a different day if desired. We pack all items professionally and with care. The costs for packing materials are complimentary except for crates. If a crate is desired for packing, the costs for materials are at buyers expense. In the Netherlands we can deliver the goods with our own white glove delivery service, ask us for delivery prices. All items are shipped on full responsibility of the buyer, even if delivery is free. After the item has been shipped, we will provide a tracking code (if available) from the carrier which can be used to follow the delivery on the carriers website. Because of the CITES agreement, some materials can not be send to certain countries. Please contact us for more information about CITES and ordering. Insurance. To avoid complications, our shipped items are always insured. The insurance price is included in the shipping costs."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Paying",
                    "answerCount": "1",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Buying online. After ordering an item through the website, the item will be placed on hold for you. We will always personally contact you to confirm your order and, if applicable, inform you about the shipping price. Next, you will be provided with payment details by e-mail within 24 hours. Payments can be made by secure bank wire transfer or Paypal. After the payment is made in our bank account, the order will be processed for shipping. We will keep you informed through e-mail. Buying at our local store. When buying an item at our local store it is only possible to pay in cash or to use a payment app, since we do not own a payment terminal."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Visiting",
                    "answerCount": "1",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Come Visit us! We have a large collection of 20th century design pieces available in our local store. Many items have their own story which we can tell you all about. Our store is located at Aalsmeerderdijk 70 sous, 1438 AT Oude Meer, the Netherlands. It can be found down the alley between the two buildings. When you come by car, we have lots of parking space available, also for a truck. Our store is located near Amsterdam en next to Schiphol airport. Make an appointment. Please note that we are open by appointment only. An appointment can be made by calling us or sending us an e-mail. Visiting times are variable. You could visit us on weekdays, but also in the weekends. You can contact us by calling +31625218612 from 9 AM till 5 PM CEST. Address: Aalsmeerderdijk 70 sous, 1438 AT Oude Meer, the Netherlands"
                    }
                }
                ]
            },
            {
                "@context":"http://schema.org",
                "@type":"BreadcrumbList",
                "itemListElement":
                [
                {"@type":"ListItem",
                    "position":1,
                    "item":{"@type":"Thing","name":"Home","@id":"https://zogoedalsoud.com"}
                },
                {"@type":"ListItem",
                    "position":2,
                    "item":{"@type":"Thing","name":"Customer info","@id":"https://zogoedalsoud.com/customer-info"}
                }
                ]
            }];

        const canonicalUrl = getCanonicalUrl();

        return (
            <div className="page-content small-margin-top">
                <Helmet>
                    <title>Customer Info | Answers for Shipping, Paying, Ordering and Who We Are</title>
                    <meta
                        name="description"
                        content="We ship worldwide and pack our items carefully. You can visit our storage by appointment only. Please contact us for any questions."
                    />
                    {canonicalUrl && <link rel={'canonical'} href={canonicalUrl} />}
                    <meta property="og:title" content="Customer Info | Anwsers for Shipping, Payment, Packaging and Who We Are" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.zogoedalsoud.com/customer-info" />
                    <meta property="og:image" content="https://shop.zogoedalsoud.com/sdImages/customer-info-ask-about-shipping-payments-ordering-visiting-and-who-we-are-at-zo-goed-als-oud-vintage-design-shop-online.jpg"/>
                    <meta property="og:image:alt" content="Customer service of Zo Goed Als Oud" />
                    <meta property="og:site_name" content="Zo Goed Als Oud" />
                    <meta
                        property="og:description"
                        content="We ship worldwide and pack our items carefully. You can visit our storage by appointment only. Please contact us for any questions."
                    />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Customer Info | Anwsers for Shipping, Payment, Packaging and Who We Are" />
                    <meta
                        name="twitter:description"
                        content="We ship worldwide and pack our items carefully. You can visit our storage by appointment only. Please contact us for any questions."
                    />
                    <meta name="twitter:url" content="https://www.zogoedalsoud.com/customer-info" />
                    <meta name="twitter:image" content="https://shop.zogoedalsoud.com/sdImages/customer-info-ask-about-shipping-payments-ordering-visiting-and-who-we-are-at-zo-goed-als-oud-vintage-design-shop-online.jpg" />
                    <meta name="twitter:image:alt" content="Customer service of Zo Goed Als Oud" />

                    <script type="application/ld+json">{JSON.stringify(structuredDataCustomerInfo)}</script>
                </Helmet>

                <div className="content-block content-block-cservice desktop-only">
                    <h2>Customer info</h2>
                    <div className="cservice-container">
                        <div className="cservice-menu">
                            <div className="cservice-list">
                                {cserviceMenuItems.map((item, i) => (
                                    <div
                                        key={'desktop-list-' + i}
                                        onClick={() => onMenuClick(i)}
                                        className={cx({
                                            active: i === activeItem,
                                        })}
                                    >
                                        <i className="fa fa-caret-right" /> {i18n.translate(item)}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="left-block">
                            <p>{i18n.translate(`${prefix}Label1`)}</p>
                            {i18n.translate(`${prefix}Text1`)}
                            <p>{i18n.translate(`${prefix}Label2`)}</p>
                            {i18n.translate(`${prefix}Text2`)}
                        </div>

                        <div className="right-block">
                            <img src={imageByIndex[activeItem]} alt={i18n.translate(prefix)} />
                        </div>
                    </div>
                </div>

                <div className="content-block content-block-cservice mobile-only">
                    <h2>Customer info</h2>

                    <div className="accordeon-container">
                        {cserviceMenuItems.map((item, i) => {
                            const label1 = i18n.translate(cserviceMenuItems[i] + 'Label1');
                            const text1 = i18n.translate(cserviceMenuItems[i] + 'Text1');

                            const label2 = i18n.translate(cserviceMenuItems[i] + 'Label2');
                            const text2 = i18n.translate(cserviceMenuItems[i] + 'Text2');

                            return (
                                <div
                                    key={'mobile-list-' + i}
                                    onClick={() => {
                                        let newActiveItems = activeItems;
                                        if (includes(activeItems, i)) {
                                            newActiveItems = activeItems.filter(item => item !== i);
                                        } else {
                                            newActiveItems.push(i);
                                        }

                                        this.setState({ activeItems: newActiveItems });
                                    }}
                                    className={'accordeon-frame'}
                                >
                                    <div className={cx('accordeon-item', { active: includes(activeItems, i) })}>
                                        <i className={cx('fa', { 'fa-caret-right': !includes(activeItems, i), 'fa-caret-down': includes(activeItems, i) })} />{' '}
                                        {i18n.translate(item)}
                                    </div>

                                    <div className={cx('mobile-customer-info', { active: includes(activeItems, i) })}>
                                        <p>{label1}</p>
                                        <span>{text1}</span>

                                        <div style={{ width: '100%', padding: '20px 0' }}>
                                            <img src={imageByIndex[i]} style={{ width: '100%' }} alt={i18n.translate(cserviceMenuItems[i])} />
                                        </div>

                                        <p>{label2}</p>
                                        <span>{text2}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerService;
