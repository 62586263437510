// Modules
import { connect } from 'react-redux';

// ActionsCreators
import { closeModal } from '../../routes/home/modules/actionCreators';

// Selectors
import { selectModalState } from '../../routes/home/modules/selectors';

// Components
import { ZGAOModal } from './ZGAOModal';

const mapStateToProps = (state, ownProps) => {
    return { modal: selectModalState(state) };
};

const mapDispatchToProps = {
    closeModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ZGAOModal);
