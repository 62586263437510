// Modules
import React, { Fragment } from 'react';
import { isFunction } from 'lodash';

// Styling
import './FormInput.css';

class FormInput extends React.PureComponent {
    handleOnChange = e => {
        isFunction(this.props.onChange) && this.props.onChange(e.target.value);
    };

    render() {
        const { label, placeHolder, name, autoFocus, required, type } = this.props;

        return (
            <Fragment>
                {label && <label>{label}</label>}
                <input
                    className="form-input"
                    required={required || false}
                    placeholder={placeHolder || 'Vul in..'}
                    type={type || 'text'}
                    name={name}
                    autoFocus={autoFocus}
                    onChange={this.handleOnChange}
                />
            </Fragment>
        );
    }
}

FormInput.defaultProps = {
    autoFocus: false,
};

export default FormInput;
