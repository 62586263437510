// Modules
import React from 'react';
import i18n from 'i18n-react';
import { get, isUndefined } from 'lodash';
import { Helmet } from 'react-helmet';

// Styling
// import './Home.css';

// Asset

//Lighting
import catFloorLight from '../../../assets/category/lighting/shop-authentic-mid-century-vintage-design-floor-light-and-other-furniture-online.jpg';
import catWallLight from '../../../assets/category/lighting/shop-authentic-mid-century-vintage-design-wall-light-and-other-furniture-online.jpg';
import catDeskLight from '../../../assets/category/lighting/shop-authentic-mid-century-vintage-design-table-and-desk-light-and-other-furniture-online.jpg';
import catPendantLight from '../../../assets/category/lighting/shop-authentic-mid-century-vintage-design-ceiling-and-pendant-and-other-furniture-online.jpg';
import catLightingAll from '../../../assets/category/lighting/shop-all-kind-of-vintage-design-lights-and-lamps-online-at-zo-goed-als-oud.jpg';

//Seating
import catDiningChair from '../../../assets/category/seating/shop-authentic-mid-century-vintage-design-dining-chairs-online.jpg';
import catOfficeChair from '../../../assets/category/seating/shop-authentic-mid-century-vintage-design-office-chairs-online.jpg';
import catLoungeChair from '../../../assets/category/seating/shop-authentic-mid-century-vintage-design-lounge-chairs-online.jpg';
import catOttoman from '../../../assets/category/seating/shop-authentic-mid-century-vintage-design-stools-and-ottomans-online.jpg';
import catSofa from '../../../assets/category/seating/shop-authentic-mid-century-vintage-design-sofas-online.jpg';
import catDaybed from '../../../assets/category/seating/shop-authentic-mid-century-vintage-design-beds-and-daybeds-online.jpg';
import catSeatingAll from '../../../assets/category/seating/shop-all-kind-of-vintage-design-seating-online-at-zo-goed-als-oud.jpg';

//Storage
import catSideboard from '../../../assets/category/storage/shop-authentic-mid-century-vintage-design-sideboard-and-highboard-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catHighboard from '../../../assets/category/storage/shop-authentic-mid-century-vintage-design-highboards-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catNightstand from '../../../assets/category/storage/shop-authentic-mid-century-vintage-design-nightstands-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catChestDrawers from '../../../assets/category/storage/shop-authentic-mid-century-vintage-design-chest-of-drawers-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catWardrobe from '../../../assets/category/storage/shop-authentic-mid-century-vintage-design-wardrobe-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catWallSystem from '../../../assets/category/storage/shop-authentic-mid-century-vintage-design-wall-system-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catStorageAll from '../../../assets/category/shop-all-kind-of-vintage-design-chairs-lights-sofas-sideboards-desks-accessories-online-at-zo-goed-als-oud.jpg';

//Tables
import catDiningTable from '../../../assets/category/tables/shop-authentic-mid-century-vintage-design-dining-tables-and-other-furniture-online.jpg';
import catCoffeeTable from '../../../assets/category/tables/shop-authentic-mid-century-vintage-design-coffee-tables-and-other-furniture-online.jpg';
import catNestingTable from '../../../assets/category/tables/shop-authentic-mid-century-vintage-design-nesting-tables-and-other-furniture-online.jpg';
import catSideTable from '../../../assets/category/tables/shop-authentic-mid-century-vintage-design-side-tables-and-other-furniture-online.jpg';
import catTrolley from '../../../assets/category/tables/shop-authentic-mid-century-vintage-design-trolley-and-bar-carts-and-other-furniture-online.jpg';
import catDesk from '../../../assets/category/tables/shop-authentic-mid-century-vintage-design-desks-and-other-furniture-online.jpg';
import catTableAll from '../../../assets/category/tables/shop-all-kind-of-vintage-design-tables-online-at-zo-goed-als-oud.jpg';

//Accessoiries
import catMirror from '../../../assets/category/accessories/shop-authentic-mid-century-vintage-design-mirrors-and-other-furniture-online.jpg';
import catCoatrack from '../../../assets/category/accessories/shop-authentic-mid-century-vintage-design-coatracks-and-other-furniture-online.jpg';
import catKids from '../../../assets/category/accessories/shop-authentic-mid-century-vintage-design-kids-items-and-other-furniture-online.jpg';
import catArt from '../../../assets/category/accessories/shop-authentic-mid-century-vintage-design-art-and-other-furniture-online.jpg';
import catMagazineStands from '../../../assets/category/accessories/shop-authentic-mid-century-vintage-design-magazine-stands-and-other-furniture-online.jpg';
import catOther from '../../../assets/category/accessories/shop-authentic-mid-century-vintage-design-unique-items-online.jpg';
import cattAccAll from '../../../assets/category/accessories/shop-all-kind-of-vintage-design-accessories-online-at-zo-goed-als-oud.jpg';

import testSeating from '../../../assets/category/shop-authentic-mid-century-vintage-design-seating-and-other-furniture-online.jpg';
import testTables from '../../../assets/category/shop-authentic-mid-century-vintage-design-tables-and-other-furniture-online.jpg';
import testStorage from '../../../assets/category/shop-authentic-mid-century-vintage-design-storage-and-other-furniture-online.jpg';
import testLight from '../../../assets/category/shop-authentic-mid-century-vintage-design-lighting-and-other-furniture-online.jpg';
import testAccess from '../../../assets/category/shop-authentic-mid-century-vintage-design-accessoires-and-other-furniture-online.jpg';

import { NavLink } from 'react-router-dom';
import { getCanonicalUrl } from '../../../core/utils';

class MobileCategory extends React.Component {
    render() {
        const { onNavigate, match } = this.props;
        const category = get(match, ['params', 'category'], 'all');

        const subcategoryTilesData = {
            all: [
                { url: '/category/seating', source: testSeating, slug: 'menuSeating' },
                { url: '/category/tables', source: testTables, slug: 'menuTables' },
                { url: '/category/storage', source: testStorage, slug: 'menuStorage' },
                { url: '/category/lighting', source: testLight, slug: 'menuLighting' },
                { url: '/category/other', source: testAccess, slug: 'menuAccessories' },
                { url: '/all', source: testAccess, slug: 'menuAllItems' },
            ],
            seating: [
                { url: '/seating/lounge-chairs', source: catLoungeChair, slug: 'Lounge' },
                { url: '/seating/dining-chairs', source: catDiningChair, slug: 'Dining' },
                { url: '/seating/office-chairs', source: catOfficeChair, slug: 'Office' },
                { url: '/seating/sofas', source: catSofa, slug: 'Sofa' },
                { url: '/seating/stools-and-ottomans', source: catOttoman, slug: 'Stool & Ottoman' },
                { url: '/seating/beds-and-daybeds', source: catDaybed, slug: 'Bed & Daybed' },
                { url: '/seating', source: catSeatingAll, slug: 'All seating' },
            ],
            tables: [
                { url: '/tables/coffee-tables', source: catCoffeeTable, slug: 'Coffee' },
                { url: '/tables/desks', source: catDesk, slug: 'Desk' },
                { url: '/tables/dining-tables', source: catDiningTable, slug: 'Dining' },
                { url: '/tables/nesting-tables', source: catNestingTable, slug: 'Nesting' },
                { url: '/tables/side-tables', source: catSideTable, slug: 'Side' },
                { url: '/tables/trolleys-and-bar-carts', source: catTrolley, slug: 'Trolley & Cart' },
                { url: '/tables', source: catTableAll, slug: 'All tables' },
            ],
            storage: [
                { url: '/storage/shelf-and-wall-systems', source: catWallSystem, slug: 'Shelving system' },
                { url: '/storage/chests-of-drawers', source: catChestDrawers, slug: 'Chest of drawers' },
                { url: '/storage/sideboards', source: catSideboard, slug: 'Sideboard' },
                { url: '/storage/highboards', source: catHighboard, slug: 'Highboard' },
                { url: '/storage/nightstands', source: catNightstand, slug: 'Nightstand' },
                { url: '/storage/wardrobes', source: catWardrobe, slug: 'Wardrobe' },
                { url: '/storage', source: catStorageAll, slug: 'All storage' },
            ],
            lighting: [
                { url: '/lighting/ceiling-and-pendant-lights', source: catPendantLight, slug: 'Ceiling & Pendant' },
                { url: '/lighting/desk-and-table-lights', source: catDeskLight, slug: 'Desk & Table' },
                { url: '/lighting/floor-lights', source: catFloorLight, slug: 'Floor' },
                { url: '/lighting/wall-lights-and-sconces', source: catWallLight, slug: 'Wall & Sconce' },
                { url: '/lighting', source: catLightingAll, slug: 'All lighting' },
            ],
            other: [
                { url: '/other/outdoor', source: catArt, slug: 'Outdoor' },
                { url: '/other/coatracks', source: catCoatrack, slug: 'Coatrack' },
                { url: '/other/kids', source: catKids, slug: 'Kids' },
                { url: '/other/magazine-stands', source: catMagazineStands, slug: 'Magazine stand' },
                { url: '/other/mirrors', source: catMirror, slug: 'Mirror' },
                { url: '/other/miscellaneous', source: catOther, slug: 'Miscellaneous' },
                { url: '/other', source: cattAccAll, slug: 'All other' },
            ],
        };

        const categoryTilesMobileData = subcategoryTilesData[category];

        const structuredDataMobileCategory = [
            {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: [
                    { '@type': 'ListItem', position: 1, item: { '@type': 'Thing', name: 'Home', '@id': 'https://zogoedalsoud.com' } },
                    {
                        '@type': 'ListItem',
                        position: 2,
                        item: { '@type': 'Thing', name: 'Category', '@id': 'https://zogoedalsoud.com/category' },
                    },
                    {
                        '@type': 'ListItem',
                        position: 3,
                        item: { '@type': 'Thing', name: category, '@id': window.location.href },
                    },
                ],
            },
        ];

        // Ga naar de 404 als route niet bestaat:
        if (isUndefined(categoryTilesMobileData)) {
            window.location.href = '/404';
        }

        // @todo: Erwin zet hier de mobile tekstjes neer
        const mobileCategoryPageDescriptions = {
            new: 'New | Just Arrived Vintage Design Items at Zo Goed Als Oud',
            sold: 'Sold | Vintage Design Items Sold by Zo Goed Als Oud',
            sale: 'ON SALE | Discounted Vintage Design Items at Zo Goed Als Oud',
            all: 'All | Shop The Vintage Design Collection at Zo Goed Als Oud',

            seating: 'Seating | Buy Vintage Design Furniture at Zo Goed Als Oud',
            tables: 'Tables | Buy Vintage Design Furniture at Zo Goed Als Oud',
            storage: 'Storage | Buy Vintage Design Furniture at Zo Goed Als Oud',
            lighting: 'Lighting | Buy Vintage Design Lighting at Zo Goed Als Oud',
            other: 'Accessories | Buy Vintage Design Items at Zo Goed Als Oud',

            //seating
            'lounge-chairs': 'Lounge Chairs | Buy Vintage Design at Zo Goed Als Oud',
            'dining-chairs': 'Dining Chairs | Buy Vintage Design at Zo Goed Als Oud',
            office: 'Office Chairs | Buy Vintage Design at Zo Goed Als Oud',
            sofas: 'Sofas | Buy Vintage Design at Zo Goed Als Oud',
            'stools-and-ottomans': 'Stools and Ottomans | Buy Vintage Design at Zo Goed Als Oud',
            'beds-and-daybeds': 'Beds and Daybeds | Buy Vintage Design at Zo Goed Als Oud',

            //tables
            'coffee-tables': 'Coffee Tables | Buy Vintage Design at Zo Goed Als Oud',
            desks: 'Desks | Buy Vintage Design at Zo Goed Als Oud',
            'dining-tables': 'Dining Tables | Buy Vintage Design at Zo Goed Als Oud',
            'nesting-tables': 'Nesting Tables | Buy Vintage Design at Zo Goed Als Oud',
            'side-tables': 'Side Tables | Buy Vintage Design at Zo Goed Als Oud',
            'trolleys-and-bar-carts': 'Trolleys and Bar Carts | Buy Vintage Design at Zo Goed Als Oud',

            //storage
            'shelf-and-wall-systems': 'Shelf & Wall Systems | Buy Vintage Design at Zo Goed Als Oud',
            'chests-of-drawers': 'Chest of Drawers | Buy Vintage Design at Zo Goed Als Oud',
            sideboards: 'Sideboards | Buy Vintage Design at Zo Goed Als Oud',
            highboards: 'Highboards | Buy Vintage Design at Zo Goed Als Oud',
            nightstands: 'Nightstands | Buy Vintage Design at Zo Goed Als Oud',
            wardrobes: 'Wardrobes | Buy Vintage Design at Zo Goed Als Oud',

            //lighting
            'ceiling-and-pendant-lights': 'Ceiling and Pendant Lights | Buy Vintage Design at Zo Goed Als Oud',
            'desk-and-table-lights': 'Desk and Table Lights | Buy Vintage Design at Zo Goed Als Oud',
            'floor-lights': 'Floor Lights | Buy Vintage Design at Zo Goed Als Oud',
            'wall-lights-and-sconces': 'Wall Lights and Sconces | Buy Vintage Design at Zo Goed Als Oud',

            //accessories
            outdoor: 'Outdoor | Buy 20th Century Outdoor Online',
            coatracks: 'Coatracks | Buy Vintage Design at Zo Goed Als Oud',
            kids: 'Kids | Buy Vintage Design at Zo Goed Als Oud',
            'magazine-stands': 'Magazine Stands | Buy Vintage Design at Zo Goed Als Oud',
            miscellaneous: 'Miscellaneous | Buy Vintage Design at Zo Goed Als Oud',
            mirrors: 'Mirrors | Buy Vintage Design at Zo Goed Als Oud',
        };

        const canonicalUrl = getCanonicalUrl();

        return (
            <div>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                    <title>{mobileCategoryPageDescriptions[category]}</title>
                    <meta name="description" content="Find and buy the vintage design item you like at Zo Goed Als Oud, online shop for 20th century design icons." />
                    {canonicalUrl && <link rel={'canonical'} href={canonicalUrl} />}
                    <meta property="og:title" content={`Category ${category} | Shop your favourite vintage design item in ${category}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta
                        property="og:image"
                        content="https://shop.zogoedalsoud.com/sdImages/zo-goed-als-oud-is-a-vintage-design-online-shop-for-20th-century-furniture-lighting-and-accesories-with-world-wide-shipping.jpg"
                    />
                    <meta property="og:image:alt" content={`Shop your category in ${category}`} />
                    <meta property="og:site_name" content="Zo Goed Als Oud" />
                    <meta property="og:description" content="Find and buy the vintage design item you like at Zo Goed Als Oud, online shop for 20th century design icons." />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={`Category ${category} | Shop your favourite vintage design item in ${category}`} />
                    <meta name="twitter:description" content="Find and buy the vintage design item you like at Zo Goed Als Oud, online shop for 20th century design icons." />
                    <meta name="twitter:url" content={window.location.href} />
                    <meta
                        name="twitter:image"
                        content="https://shop.zogoedalsoud.com/sdImages/zo-goed-als-oud-is-a-vintage-design-online-shop-for-20th-century-furniture-lighting-and-accesories-with-world-wide-shipping.jpg"
                    />
                    <meta name="twitter:image:alt" content={`Shop your category in ${category}`} />

                    <script type="application/ld+json">{JSON.stringify(structuredDataMobileCategory)}</script>
                </Helmet>

                <div className="page-content">
                    <div className="content-block">
                        <div className={'home-intro'}>Select your category in {category}</div>

                        {/*<h2>{i18n.translate('shopByCategory')}</h2>*/}
                        <div className={'shop-by-category'} style={{ paddingTop: '11px' }}>
                            {(categoryTilesMobileData || []).map(({ url, source, slug }) => (
                                <div key={url} className="category-item" onClick={() => onNavigate({ url })}>
                                    <NavLink to={url}>
                                        <img src={source} alt={url} />
                                        <div className="category-item-label">{i18n.translate(slug)}</div>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileCategory;
