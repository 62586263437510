export default {
    //Home
    shopByCategory: "Shop by category",
    shopOurLatest: "Shop our latest products",
    seeMoreItems: "See more items",

    // Products
    price_per_piece: "per piece",
    price_per_set: "per set",

    //FrameTop
    frameTopLeft: "DEALER OF UNIQUE VINTAGE DESIGN PIECES",
    frameTopCenter: "BUY 20TH CENTURY DESIGN FURNITURE, LIGHTING, AND ACCESSORIES",
    frameTopRight: "INSURED WORLDWIDE SHIPPING AVAILABLE",

    // Options Menu
    langEn: "Eng",
    langNl: "Ned",

    // Footer
    recentlyViewed: "Recently viewed items",

    // Cookie-banner
    cookieBannerText: 'We use cookies from Google to provide necessary site functionality and improve your experience. By accepting, you agree to this.',
    cookieBannerAccept: 'Accept',
    cookieBannerReject: 'Decline',

    //Customer-service
    cserviceOrdening: "Our items",
    cserviceOrdeningLabel1: "What we offer",
    cserviceOrdeningLabel2: "Item condition",
    cserviceOrdeningText1: "Zo Goed Als Oud offers items for sale through her website or local store in Oude Meer. The items we offer are furniture, lighting and accessories from the 20th century. Most of those items are from recognized designers and highly collectable.",    
    cserviceOrdeningText2: "We try to describe the condition of these items as clear as possible on our website. Our Items are always thoroughly inspected and sometimes restored with new upholstery, new paint, new veneer, repairs etcetera when neccessary. This will be described in the item description on the item page and is always with respect to the designer of the piece. Please note that all of our items are vintage (not new) and can have some wear. For example, some of our items are over 60 years old.",

    cserviceShipping: "Shipping",
    cserviceShippingLabel1: "Global shipping",
    cserviceShippingLabel2: "Insurance",
    cserviceShippingText0:
        "We ship worldwide and pack items ourselves. We use professional shipping companies for secure transport. Shipping cost are excluded from the prices you see on our website. Please ask us for the shipping cost of an item and we can give you the most secure and beneficial option. Please be aware that shipping costs can vary from day to day.",
    cserviceShippingText1:
        "If an item is ordered, the shipment is sent as soon as possible (1-5 days) after receipt of payment in our bank account. It is possible to sent the shipment a different day if desired. We pack all items professionally and with care. The costs for packing materials are complimentary except for crates. If a crate is desired for packing, the costs for materials are at buyers expense. In the Netherlands we can deliver the goods with our own white glove delivery service, ask us for delivery prices. All items are shipped on full responsibility of the buyer, even if delivery is free. After the item has been shipped, we will provide a tracking code (if available) from the carrier which can be used to follow the delivery on the carriers website.\n\n Because of the CITES agreement, some materials can not be send to certain countries. Please contact us for more information about CITES and ordering.",
    cserviceShippingText2:
        "To avoid complications, our shipped items are always insured. The insurance price is included in the shipping costs.",

    cserviceTerms: "Ordering",
    cserviceTermsLabel1: "How to order",
    cserviceTermsLabel2: "Returning",
    cserviceTermsText1: "Items can be ordered trough our website or purchased in our local store. Because we sell vintage items with history and character, which can have wear, we would like to have some personal contact after ordering. That is why there is no paying or checkout option on our website. Items can be ordered by filling in the order item form on a product page or by calling us from 9 AM till 5 PM CEST. In this way we can deliver you the best possible care and details about an item you would like to buy. Please contact us if you would like to order multiple products in one order or use the favorites function on the website. It is also possible to see, inspect and buy the items in our local store in Oude Meer, the Netherlands. Please note that we are open by appointment only.",
    cserviceTermsText2: "By European law, customers of The European Union have the right of withdrawal within 14 days after the day they received an item bought from a webshop. If you would like to return an item, please contact us within the 14 days after the day you received the item. Please note that the shipping costs for returning are always at buyers expense. Also, the item has to be returned in the same condition as it was received and must be carefully packed for transport. Returning is on full responsibility of the buyer.",

    cserviceReturning: "Paying",
    cserviceReturningLabel1: "Buying online",
    cserviceReturningLabel2: "Buying at our local store",
    cserviceReturningText1: "After ordering an item through the website, the item will be placed on hold for you. We will always personally contact you to confirm your order and if applicable inform you about the shipping price. Next, you will be provided with payment details by e-mail within 24 hours. Payments can be made by secure bank wire transfer or Paypal. After the payment is made in our bank account, the order will be processed for shipping. We will keep you informed through e-mail.",
    cserviceReturningText2: "When buying an item at our local store it is only possible to pay in cash or to use a payment app, since we do not own a payment terminal.",

    cserviceAbout: "About us",
    cserviceAboutLabel1: "Zo Goed Als Oud",
    cserviceAboutLabel2: "Our store",
    cserviceAboutText1: "Zo Goed Als Oud, meaning “As Good As Old” in Dutch, was founded in 2011 to meet the need and demand for furniture, lighting and accessories from the 20th century.\n\nThe founder was Fleur Bannink, then already a large collector of items from the 20th century. She graduated from the Artemis Design Academy in 2009, where she learned to combine these items with contemporary design. More and more often the question arose whether the items she collected and used for projects were also for sale. Apparently more people were charmed by the timeless design of these items.\n\nThis need and demand became what is now Zo Goed Als Oud. An online and local store for items from the 20th century. Refurbished when necessary and ready for global shipping.",
    cserviceAboutText2: "Our store consists of a large storage filled with chairs, couches, tables, lamps, dressers, vases, artworks and more. Everything is from the 20th century and most of well-known designers. A true treasure house for every design enthusiast.\n\nWe do everything ourselves. We first search our network for new and beautiful items. We then fix them up when necessary in our own workshop. There they are re-upholstered with the right fabric or they receive a new layer of lacquer or repair. When that is done, they are photographed and presented to the world through our website.\n\nAs a result, we know everything about the items that we sell. And we think this is important, because we love them as much as you do.\n\n",

    cserviceVisiting: "Visiting",
    cserviceVisitingLabel1: "Come Visit us!",
    cserviceVisitingLabel2: "Make an appointment",
    cserviceVisitingText1: "We have a large collection of 20th century design pieces available in our local store. Many items have their own story which we can tell you all about. Our store is located at Aalsmeerderdijk 70 sous, 1438 AT Oude Meer, the Netherlands. It can be found down the alley between the two buildings. When you come by car, we have lots of parking space available, also for a truck. Our store is located near Amsterdam en next to Schiphol airport.",
    cserviceVisitingText2: "Please note that we are open by appointment only. An appointment can be made by calling us or sending us an e-mail. Visiting times are variable. You could visit us on weekdays, but also in the weekends.\n\nYou can contact us by calling +31625218612 from 9 AM till 5 PM CEST\n\nAddress: Aalsmeerderdijk 70 sous, 1438 AT Oude Meer, the Netherlands\n\n",

    // Menu
    menuNewArrivals: "New Arrivals",
    menuAllProducts: "All Products",
    menuSeating: "Seating",
    menuTables: "Tables",
    menuStorage: "Storage",
    menuLighting: "Lighting",
    menuAccessories: "Other",
    menuSales: "Sale",
    menuSold: "Sold",
    menuAllItems: "All Items",

    SeatingTitle: "Shop our collection of vintage design seating",
    TablesTitle: "Shop our collection of vintage design tables",
    StorageTitle: "Shop our collection of vintage design storage",
    LightingTitle: "Shop our collection of vintage design lighting",
    OtherTitle: "Shop our collection of other vintage design pieces"
};
