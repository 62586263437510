export const FETCH_API_LOADING = 'api/FETCH_API_LOADING';
export const FETCH_API_SUCCESS = 'api/FETCH_API_SUCCESS';
export const FETCH_API_ERROR = 'api/FETCH_API_ERROR';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_DESIGNERS = 'GET_DESIGNERS';
export const GET_FEATURED_PRODUCTS = 'GET_FEATURED_PRODUCTS';
export const GET_SHOP_LATEST_PRODUCTS = 'GET_SHOP_LATEST_PRODUCTS';
export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
export const GET_PRODUCTS_BY_DESIGNER = 'GET_PRODUCTS_BY_DESIGNER';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';

export const UPDATE_DESIGNERS_BY_SLUG_STATE = 'UPDATE_DESIGNERS_BY_SLUG_STATE';
export const UPDATE_PRODUCTS_BY_SLUG_STATE = 'UPDATE_PRODUCTS_BY_SLUG_STATE';
