// Modules
import React from 'react';

// Styling
import './SubmitButton.scss';

class SubmitButton extends React.Component {
    render() {
        const { text, buttonClassName, type, tempDisabled } = this.props;
        return (
            <button type={type} className={'submit-button ' + buttonClassName} disabled={tempDisabled}>
                {text}
            </button>
        );
    }
}

SubmitButton.defaultProps = {
    type: 'button',
    text: 'Submit',
    // onClick: () => alert('Hi! '),
};

export default SubmitButton;
