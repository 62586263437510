// Modules
import React from 'react';
import i18n from 'i18n-react';
import { Helmet } from 'react-helmet';

// Styling
import './Home.css';

// Asset
import testLight from '../../../assets/category/shop-authentic-mid-century-vintage-design-lighting-and-other-furniture-online.jpg';
import testSeating from '../../../assets/category/shop-authentic-mid-century-vintage-design-seating-and-other-furniture-online.jpg';
import testStorage from '../../../assets/category/shop-authentic-mid-century-vintage-design-storage-and-other-furniture-online.jpg';
import testTables from '../../../assets/category/shop-authentic-mid-century-vintage-design-tables-and-other-furniture-online.jpg';
import testAccess from '../../../assets/category/shop-authentic-mid-century-vintage-design-accessoires-and-other-furniture-online.jpg';
import allItemsHome from '../../../assets/category/shop-all-kind-of-vintage-design-chairs-lights-sofas-sideboards-desks-accessories-online-at-zo-goed-als-oud.jpg';
import placeHolderNarrow from '../../../assets/want-to-sell-your-item-we-buy-your-vintage-design-furniture.jpg';
import placeHolderWide from '../../../assets/visit-our-storage-and-find-and buy-your-vintage-design-item.jpg';
import aboutLogo from '../../../assets/ZGAO.svg';

// Components
import Carousel from '../../../@shared/components/Carousel';
import ShopLatestProducts from '../../../@shared/components/ShopLatestProducts';
import GalleryMobile from '../../../@shared/components/MobileGallery';
import { LinkComponent } from '../../../@shared/components/LinkComponent';

let rtime;
let timeout = false;
let delta = 200;

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderMobile: window.innerWidth < 1051,
        };
    }

    resizeend = () => {
        if (new Date() - rtime < delta) {
            setTimeout(this.resizeend, delta);
        } else {
            timeout = false;
            this.setState({ renderMobile: window.innerWidth < 1051 });
        }
    };

    onResizeWindow = () => {
        rtime = new Date();
        if (timeout === false) {
            timeout = true;

            setTimeout(this.resizeend, delta);
        }
    };

    componentDidMount() {
        const onResizeFunc = this.onResizeWindow;
        window.addEventListener('resize', onResizeFunc, { passive: true });
    }

    componentWillUnmount() {
        const onResizeFunc = this.onResizeWindow;
        window.removeEventListener('resize', onResizeFunc);
    }

    render() {
        const { highlightedProducts, shopLatestProducts, onNavigate } = this.props;

        const categoryTilesData = [
            { url: '/seating', source: testSeating, slug: 'menuSeating' },
            { url: '/tables', source: testTables, slug: 'menuTables' },
            { url: '/storage', source: testStorage, slug: 'menuStorage' },
            { url: '/lighting', source: testLight, slug: 'menuLighting' },
            { url: '/other', source: testAccess, slug: 'menuAccessories' },
        ];

        const categoryTilesMobileData = [
            { url: '/category/seating', source: testSeating, slug: 'menuSeating' },
            { url: '/category/tables', source: testTables, slug: 'menuTables' },
            { url: '/category/storage', source: testStorage, slug: 'menuStorage' },
            { url: '/category/lighting', source: testLight, slug: 'menuLighting' },
            { url: '/category/other', source: testAccess, slug: 'menuAccessories' },
            { url: '/all', source: allItemsHome, slug: 'menuAllItems' },
        ];

        const structuredDataHome = {
            '@context': 'https://schema.org',
            '@type': 'FurnitureStore',
            name: 'Zo Goed Als Oud',
            alternateName: 'ZGAO',
            description:
                'Zo Goed Als Oud, meaning As Good As Old, is a Dutch-based vintage design furniture, lighting and accessories dealer with an online shop and local store. Since our start in 2011, we have built up a large collection of vintage design pieces by many famous designers. Those items are thoroughly inspected and refurbished when necessary, always with respect to the designer. Many of our pieces have already found a home with happy customers all over the world. You can buy these items at our local store in the Netherlands, near Amsterdam and Schiphol airport. Or directly online from our webshop for insured world wide delivery.',
            url: 'https://www.zogoedalsoud.com',
            sameAs: ['https://www.instagram.com/zogoedalsoud/?hl=en'],
            image:
                'https://shop.zogoedalsoud.com/sdImages/zo-goed-als-oud-is-a-vintage-design-online-shop-for-20th-century-furniture-lighting-and-accesories-with-world-wide-shipping.jpg',
            logo: 'https://shop.zogoedalsoud.com/sdImages/ZGAO-online-shop-for-vintage-design-furniture-lighting-and-accessories.svg',
            telephone: '+31625218612',
            email: 'contact@zogoedalsoud.com',
            foundingDate: '2011',
            areaServed: 'Worldwide',
            priceRange: '$$$$',

            address: [
                {
                    '@type': 'PostalAddress',
                    streetAddress: 'Aalsmeerderdijk 70 sous',
                    addressLocality: 'Oude Meer',
                    addressRegion: 'NH',
                    postalCode: '1438 AT',
                    addressCountry: 'NL',
                },
            ],
        };

        // We need an id for higlihgted items to force a rerender.
        // KA-53 : https://holywebapp.atlassian.net/browse/KA-53
        const customHackId = highlightedProducts.length;

        return (
            <div>
                <Helmet>
                    <title>ZGAO | Buy Vintage Design Furniture, Lighting and Accessories</title>
                    <meta
                        name="description"
                        content="Zo Goed Als Oud is a dealer with an online shop and local store for authentic and unique vintage design furniture, lighting and accessories. Global shipping available."
                    />

                    <meta property="og:title" content="ZGAO | Buy Vintage Design Furniture, Lighting and Accessories" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.zogoedalsoud.com" />
                    <meta
                        property="og:image"
                        content="https://shop.zogoedalsoud.com/sdImages/zo-goed-als-oud-is-a-vintage-design-online-shop-for-20th-century-furniture-lighting-and-accesories-with-world-wide-shipping.jpg"
                    />
                    <meta property="og:image:alt" content="Website of Zo Goed Als Oud, dealer of vintage design furniture" />
                    <meta property="og:site_name" content="Zo Goed Als Oud" />
                    <meta
                        property="og:description"
                        content="Zo Goed Als Oud is a dealer with an online shop and local store for authentic and unique vintage design furniture, lighting and accessories. Global shipping available."
                    />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="ZGAO | Buy Vintage Design Furniture, Lighting and Accessories" />
                    <meta
                        name="twitter:description"
                        content="Zo Goed Als Oud is a dealer with an online shop and local store for authentic and unique vintage design furniture, lighting and accessories. Global shipping available."
                    />
                    <meta name="twitter:url" content="https://www.zogoedalsoud.com" />
                    <meta
                        name="twitter:image"
                        content="https://shop.zogoedalsoud.com/sdImages/zo-goed-als-oud-is-a-vintage-design-online-shop-for-20th-century-furniture-lighting-and-accesories-with-world-wide-shipping.jpg"
                    />
                    <meta name="twitter:image:alt" content="Website of Zo Goed Als Oud, dealer of vintage design furniture" />

                    <script type="application/ld+json">{JSON.stringify(structuredDataHome)}</script>
                </Helmet>

                <div className={'desktop-only'}>{!this.state.renderMobile && <Carousel images={highlightedProducts} />}</div>

                <div className={'tablet-carousel'}>
                    <div className="page-content">
                        <div className="content-block favourite-items">
                            <div className={'home-intro tablet-only'}>Dealer of unique vintage design pieces.</div> {/*Save up to 35% <a href="/sale">Shop the Winter Sale!</a>*/}

                            {this.state.renderMobile && (
                                <GalleryMobile id={customHackId} renderProductName products={highlightedProducts} items={highlightedProducts.map(product => product.images[0])} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="content-block">
                        <div className={'home-intro mobile-only'}>Dealer of unique vintage design pieces.</div> {/* Save up to 35% <a href="/sale">Shop the Winter Sale!</a> */}
                        
                        <h2 style={{ paddingTop: '18px' }}>{i18n.translate('shopByCategory')}</h2>
                        <div className={'shop-by-category desktop-only'}>
                            {categoryTilesData.map(({ url, source, slug }) => (
                                <div key={url} className="category-item">
                                    <LinkComponent url={url} onClick={() => onNavigate({ url })}>
                                        <img src={source} alt={url} />
                                        <div className="category-item-label">{i18n.translate(slug)}</div>
                                    </LinkComponent>
                                </div>
                            ))}
                        </div>
                        <div className={'shop-by-category mobile-only'}>
                            {categoryTilesMobileData.map(({ url, source, slug }) => (
                                <div key={'mobile-' + url} className="category-item">
                                    <LinkComponent url={url} onClick={() => onNavigate({ url })}>
                                        <img src={source} alt={url} />
                                        <div className="category-item-label">{i18n.translate(slug)}</div>
                                    </LinkComponent>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="page-content home-grey">
                    <div className="content-block">
                        <h2 className="shop-latest">{i18n.translate('shopOurLatest')}</h2>
                        <div className={'home-shop-latest-container'}>
                            <ShopLatestProducts items={shopLatestProducts} seeMoreItems={true} showNameAndPrice={true} onNavigate={onNavigate} />
                        </div>
                        <div className="double-info-blocks desktop-only">
                            <div className="info-block" onClick={() => window.location = 'mailto:contact@zogoedalsoud.com'}>
                                <div className="info-block-wide zgao-gold">
                                    <h3>
                                        WE BUY YOUR <br /> VINTAGE DESIGN ITEMS!
                                    </h3>
                                    <p>
                                        There may come a time you will decide to let go your vintage design items. Just because you want something new, or maybe because it doesn’t
                                        ﬁt your room anymore. We can sometimes buy your items and make sure it will get a new happy owner.
                                    </p>
                                    <p>Write us a mail, add some images and we will respond with an offer.</p>
                                </div>
                                <div className="info-block-narrow">
                                    <img onClick={() => window.location = 'mailto:contact@zogoedalsoud.com'} style={{ cursor: 'pointer' }} src={placeHolderNarrow} alt={'Sell your vintage design items'} />
                                </div>
                            </div>

                            <div className="info-block">
                                <div className="info-block-narrow zgao-gray">
                                    <h3>
                                        VISIT OUR <br />
                                        STORE
                                    </h3>
                                    <p>
                                        You can visit our store by appointment only. Please contact us to make an appointment. We are happy to welcome you and show our
                                        collection of vintage design.
                                    </p>
                                </div>
                                <div className="info-block-wide">
                                    <img onClick={() => this.props.openModal(1)} style={{ cursor: 'pointer' }} src={placeHolderWide} alt={'Make an appointment'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="content-block about-container">
                        <img className="about-logo" alt="Zo Goed Als Oud vintage design online store for 20th century furniture, lighting and accessories" src={aboutLogo} />

                        <div className="about-description">
                            Zo Goed Als Oud, meaning As Good As Old, is a Dutch-based vintage design furniture, lighting and accessories dealer with an online shop and local store.
                            Since our start in 2011, we have built up a large collection of vintage design pieces by many famous designers. Those items are thoroughly inspected and
                            refurbished when necessary, always with respect to the designer. Many of our pieces have already found a home with happy customers all over the world.
                            <br />
                            <br />
                            You can buy these items at our local store in the Netherlands, near Amsterdam and Schiphol airport. Or directly online from our webshop for insured world wide delivery.
                        </div>

                        <div className="about-slogan">With Zo Goed Als Oud, we want to offer you the best and most beautiful design objects from 20th Century.</div>
                    </div>
                </div>

                <div className="mobile-only">
                    <div className="page-content">
                        <div className="content-block favourite-items">
                            <h2>Our Favorite Items</h2>
                            {this.state.renderMobile && (
                                <GalleryMobile id={customHackId} products={highlightedProducts} renderProductName items={highlightedProducts.map(product => product.images[0])} />
                            )}
                        </div>
                    </div>

                    <div className="mobile-info-blocks">
                        <div className={'mobile-info-block show-your-items'} onClick={() => window.location = 'mailto:contact@zogoedalsoud.com'}>
                            <span>We buy your vintage design items!</span>
                            <span>
                                <i className="fa fa-caret-right" />
                                Show us your items
                            </span>
                        </div>

                        <div className="mobile-info-block make-an-appointment" onClick={() => this.props.openModal(1)}>
                            <span>Visit our store</span>
                            <span>
                                <i className="fa fa-caret-right" />
                                Make an appointment
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
