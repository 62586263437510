// Modules
import React, { useState } from 'react';
import i18n from 'i18n-react/dist/i18n-react';
import { isUndefined, capitalize, isNil, isEmpty, replace, toString } from 'lodash';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// Components
import Gallery from '../../../@shared/components/Gallery';
import GalleryMobile from '../../../@shared/components/MobileGallery';
import ShopLatestProducts from '../../../@shared/components/ShopLatestProducts';

// Utils
import { updateRecentlyViewed } from '../containers/ProductContainer';
import { getCanonicalUrl } from '../../../core/utils';
import { makeThumbnailSource } from '../../../@shared/components/Carousel';
import favorites from '../../../core/favorites-storage';

// Constants
import { DEFAULT_PRODUCT } from '../../home/modules/constants';

import LOADING_IMAGE from '../../../assets/loadingImage_1.svg';

// Styling
import './Product.css';
import './Product-Mobile.scss';
import './FavoriteButton.scss';

const renderPriceLabel = value => {
    if (value === 'loading') return '';
    return `€${value},-`;
};

const getAttribute = (attributes, attribute) => {
    try {
        return attributes
            .filter(item => item.name === attribute)[0]
            .options[0].split('-')
            .map(item => `${capitalize(item)} `);
    } catch (e) {
        console.assert(e);
    }
    return '';
};

const getAttributes = (attributes, attribute) => {
    try {
        return attributes
            .filter(item => item.name === attribute)[0]
            .options.map((item, index, arr) => (index === arr.length - 1 ? `${capitalize(item)} ` : `${capitalize(item)}, `));
    } catch (e) {
        console.assert(e);
    }
    return '';
};

const renderHelmetDesignerName = string => replace(string, /,/g, '');

const renderBreadCrumbUrl = string => replace(string, /-/g, ' ');

const getDesignerDescription = (product, designers) => {
    try {
        const designerName = product.attributes.filter(item => item.name === 'designer')[0].options[0];
        return designers.find(item => item.name === designerName).description;
    } catch (e) {
        console.assert(e);
    }
    return '';
};

const DesignerNavigation = props => {
    const { children, attributes } = props;
    try {
        const designerName = attributes.filter(item => item.name === 'designer')[0].options[0];
        return <NavLink to={`/designer/${designerName}`}>{children}</NavLink>;
    } catch (e) {
        console.assert(e);
    }

    return children;
};

const ignore = () => 'ignored';

const AddToFavoritesButton = props => {
    const { product, setCountFavorites } = props;

    const item = {
        id: product.id,
        url: window.location.href,
        image: product.images[0] ? makeThumbnailSource(product.images[0].src) : LOADING_IMAGE,
        name: product.name,
    };
    const [isFavorite, setFavorite] = useState(!!favorites.get(item));
 // ignore the isFavorite value;
    ignore(isFavorite);
    const _isFavorite = !!favorites.get(item);
    return (
        <div className={'heart-button-container'}>
            <input
                type="checkbox"
                id="checkbox"
                checked={!!favorites.get(item)}
                onChange={() => {
                    if (!!favorites.get(item)) {
                        favorites.remove(item);
                    } else {
                        favorites.add(item);
                    }
                    setFavorite(!favorites.get(item));
                    const count = favorites.count();
                    setCountFavorites(count);
                }}
            />
            <label htmlFor="checkbox" title={_isFavorite ? 'Remove from favorites' : 'Add to favorites'}>
                <svg id="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group" fill="none" fillRule="evenodd" transform="translate(467 392)">
                        <path
                            d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                            id="heart"
                            fill="#AAB8C2"
                        />
                        <circle id="main-circ" fill="#E2264D" opacity="0" cx="29.5" cy="29.5" r="1.5" />

                        <g id="grp7" opacity="0" transform="translate(7 6)">
                            <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2" />
                            <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2" />
                        </g>

                        <g id="grp6" opacity="0" transform="translate(0 28)">
                            <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2" />
                            <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2" />
                        </g>

                        <g id="grp3" opacity="0" transform="translate(52 28)">
                            <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2" />
                            <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2" />
                        </g>

                        <g id="grp2" opacity="0" transform="translate(44 6)">
                            <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2" />
                            <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2" />
                        </g>

                        <g id="grp5" opacity="0" transform="translate(14 50)">
                            <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2" />
                            <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2" />
                        </g>

                        <g id="grp4" opacity="0" transform="translate(35 50)">
                            <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2" />
                            <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2" />
                        </g>

                        <g id="grp1" opacity="0" transform="translate(24)">
                            <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2" />
                            <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2" />
                        </g>
                    </g>
                </svg>
            </label>
        </div>
    );
};

class Product extends React.Component {
    state = {
        isMobilePurchaseFormOpen: false,
        isMobile: window.innerWidth < 768,
    };

    componentDidMount() {
        // Just to be sure, dispatch the loading product action
        this.props.getProductBySlug({ slug: this.props.match.params.product });
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if (this.state.isMobile && window.innerWidth > 767) {
            this.setState({ isMobile: false });
        }
        if (!this.state.isMobile && window.innerWidth <= 767) {
            this.setState({ isMobile: true });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const { isMobile } = this.state;
        const { product, designers, relatedProducts, onNavigate, match } = this.props;
        // If the product is not here, and status is not loading, the product doesnt exist...
        if (isUndefined(product)) {
            return <div>Oops! Sorry! This page is not available.</div>;
        }

        const designerInfo = getDesignerDescription(product, designers);

        const priceType = product.attributes.find(item => item.name === 'price_per');
        const priceTypeLabel = priceType ? priceType.options[0] : '';

        const isSold = product.stock_status === 'outofstock';
        const isOnHold = !isNil(product.attributes.find(item => item.name === 'on_hold'));
        const isOnRequest = !isNil(product.attributes.find(item => item.name === 'on_request'));

        const tableBottomHeight = getAttribute(product.attributes, 'floor_to_table_bottom');
        const seatingHeight = getAttribute(product.attributes, 'seating_height');
        const seatingDepth = getAttribute(product.attributes, 'seating_depth');
        const seatingWidth = getAttribute(product.attributes, 'seating_width');
        const condition = getAttributes(product.attributes, 'condition');

        const designer = getAttribute(product.attributes, 'designer');
        const producer = getAttribute(product.attributes, 'producer');
        const year = getAttribute(product.attributes, 'production_date');
        const period = getAttribute(product.attributes, 'period');
        const origin = getAttribute(product.attributes, 'place_of_origin');
        const material = getAttributes(product.attributes, 'material');
        const productColor = getAttributes(product.attributes, 'color');

        // Strip all HTML tags from text
        const description = product.description;//.replace(/<[^>]*>?/gm, '');

        const __relatedProducts = isEmpty(relatedProducts) ? [DEFAULT_PRODUCT, DEFAULT_PRODUCT, DEFAULT_PRODUCT, DEFAULT_PRODUCT] : relatedProducts.filter((item, idx) => idx < 4);

        if (product.id !== 'loading') {
            updateRecentlyViewed(product);
        }

        const whatsappMessage = encodeURIComponent(`Hi, I have a question about ${window.location.href}`);

        const structuredDataProduct = [
            {
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: product.name,
                brand: {
                    '@type': 'Person',
                    name: isEmpty(designer) ? 'Zo Goed Als Oud' : renderHelmetDesignerName(toString(designer)),
                },
                category: {
                    '@type': 'Thing',
                    name: product && product.categories ? product.categories[0].name : 'Vintage design',
                },
                image: product.images[0] ? product.images[0].src : LOADING_IMAGE,
                description,
                sku: product.sku,
                offers: {
                    '@type': 'Offer',
                    url: window.location.href,
                    priceCurrency: 'EUR',
                    price: product.price,
                    itemCondition: 'https://schema.org/UsedCondition',
                    availability: isSold ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock',
                },
            },
            {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: [
                    { '@type': 'ListItem', position: 1, item: { '@type': 'Thing', name: 'Home', '@id': 'https://zogoedalsoud.com' } },
                    {
                        '@type': 'ListItem',
                        position: 2,
                        item: { '@type': 'Thing', name: renderBreadCrumbUrl(match.params.category), '@id': 'https://zogoedalsoud.com/' + match.params.category + '' },
                    },
                    {
                        '@type': 'ListItem',
                        position: 3,
                        item: {
                            '@type': 'Thing',
                            name: renderBreadCrumbUrl(match.params.subcategory),
                            '@id': 'https://zogoedalsoud.com/' + match.params.category + '/' + match.params.subcategory + '',
                        },
                    },
                    { '@type': 'ListItem', position: 4, item: { '@type': 'Thing', name: product.name, '@id': window.location.href } },
                ],
            },
        ];

        const canonicalUrl = getCanonicalUrl();

        return (
            <div className="product-page">
                <Helmet>
                    <title>{product.name} for sale at Zo Goed Als Oud</title>
                    <meta name="description" content={description} />
                    {canonicalUrl && <link rel={'canonical'} href={canonicalUrl} />}
                    <meta property="og:title" content={product.name} />
                    <meta property="og:type" content="og:product" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:image" content={product.images[0] ? makeThumbnailSource(product.images[0].src) : LOADING_IMAGE} />
                    <meta property="og:image:alt" content={product.name} />
                    <meta property="og:site_name" content="Zo Goed Als Oud" />
                    <meta property="og:description" content={description} />
                    <meta property="product:price:currency" content="EUR" />
                    <meta property="product:price:amount" content={product.price} />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={product.name} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:url" content={window.location.href} />
                    <meta name="twitter:image" content={product.images[0] ? makeThumbnailSource(product.images[0].src) : LOADING_IMAGE} />
                    <meta name="twitter:image:alt" content={product.name} />

                    <script type="application/ld+json">{JSON.stringify(structuredDataProduct)}</script>
                </Helmet>

                <h1>
                    <div className={'product-name-title'}>{product.name}</div>
                    {product.id !== 'loading' && !isMobile && !isSold && (
                        <AddToFavoritesButton product={product} setCountFavorites={this.props.setCountFavorites} favoritesTriggered={this.props.favoritesTriggered} />
                    )}
                </h1>

                <div className="mobile-only">
                    <GalleryMobile items={product.images} id={product.id} />
                </div>

                <div className="tablet-only">
                    <GalleryMobile items={product.images} id={product.id} />
                </div>

                <div className="product-gallery-container">
                    <div className="slide-container desktop-only">
                        <Gallery images={product.images} id={product.id} />
                    </div>

                    <div className="product-price-container">
                        <div className="price-card">
                            {isSold && (
                                <>
                                    <div className="mobile-price-card mobile-only">
                                        <span>SOLD OUT</span>
                                    </div>
                                    <div className={'sold-out-price-card mobile desktop-only'}>SOLD OUT</div>
                                </>
                            )}
                            {isOnHold && !isSold && (
                                <>
                                    <div className="mobile-price-card mobile-only">
                                        <span>ON HOLD</span>
                                        {product.id !== 'loading' && isMobile && (
                                            <AddToFavoritesButton
                                                product={product}
                                                setCountFavorites={this.props.setCountFavorites}
                                                favoritesTriggered={this.props.favoritesTriggered}
                                            />
                                        )}
                                    </div>
                                    <div className={'sold-out-price-card desktop-only'}>ON HOLD</div>
                                </>
                            )}
                            {isOnRequest && !isOnHold && !isSold && (
                                <>
                                    <div className="on-request desktop-only">
                                        <span>{`${isNil(product.stock_quantity) ? 1 : product.stock_quantity}${product.on_sale ? 'x' : ' in stock'}`}</span>
                                        <div className={'sold-out-price-card'}>PRICE ON REQUEST</div>
                                    </div>
                                    <div className="mobile-price-card mobile-only">
                                        <span>PRICE ON REQUEST</span>
                                        <span>{`${isNil(product.stock_quantity) ? 1 : product.stock_quantity} in stock`}</span>
                                        {product.id !== 'loading' && isMobile && (
                                            <AddToFavoritesButton
                                                product={product}
                                                setCountFavorites={this.props.setCountFavorites}
                                                favoritesTriggered={this.props.favoritesTriggered}
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            {!isOnRequest && !isSold && !isOnHold && (
                                <>
                                    <div className="mobile-price-card mobile-only">
                                        {product.on_sale && <div className="old-price">{renderPriceLabel(product.regular_price)}</div>}
                                        <label>{renderPriceLabel(product.price)}</label>
                                        {priceTypeLabel && <span>{i18n.translate(priceTypeLabel)}</span>}
                                        {product.price !== 'loading' && <span>{`${isNil(product.stock_quantity) ? 1 : product.stock_quantity} in stock`}</span>}
                                        {product.id !== 'loading' && isMobile && (
                                            <AddToFavoritesButton
                                                product={product}
                                                setCountFavorites={this.props.setCountFavorites}
                                                favoritesTriggered={this.props.favoritesTriggered}
                                            />
                                        )}
                                    </div>
                                    <div className="desktop-only">
                                        {product.price !== 'loading' && (
                                            <span>{`${isNil(product.stock_quantity) ? 1 : product.stock_quantity}${product.on_sale ? 'x' : ' in stock'}`}</span>
                                        )}
                                        {product.on_sale && <div className="old-price">{renderPriceLabel(product.regular_price)}</div>}
                                        <br />
                                        <label>{renderPriceLabel(product.price)}</label>
                                        <br />
                                        <span>{i18n.translate(priceTypeLabel)}</span>
                                    </div>
                                </>
                            )}
                        </div>

                        {!isSold && !isOnHold && !isOnRequest && (
                            <>
                                <div className="purchase" onClick={() => {ReactGA.event({category: 'Buyer', action: 'Pressed purchase button', label: product.name}); this.props.openModal(3, { name: product.name, url: window.location.href });}}>
                                    <i className="fa fa-caret-right" />
                                    <span>Order Item</span>
                                </div>
                            </>
                        )}

                        {!isSold && !isOnHold && !isOnRequest && !product.shipping_class && (
                            <div className="request-shipping" onClick={() => {ReactGA.event({category: 'Buyer', action: 'Pressed shipping button', label: product.name}); this.props.openModal(5, { name: product.name, url: window.location.href });}}>
                                <i className="fa fa-caret-right" />
                                <span>Request Shipping Quote</span>
                            </div>
                        )}

                        {!isSold && !isOnHold && !isOnRequest && product.shipping_class === 'free-shipping' &&(
                            <div className="free-shipping">
                                <span>Free worldwide shipping!</span>
                            </div>
                        )}

                        {isOnRequest && !isSold && !isOnHold && (
                            <div className="purchase" onClick={() => {ReactGA.event({category: 'Buyer', action: 'Pressed request button', label: product.name}); this.props.openModal(4, { name: product.name, url: window.location.href });}}>
                                <i className="fa fa-caret-right" />
                                <span>Request price</span>
                            </div>
                        )}
                        

                        <div className={cx('contact-question', { 'sold-out': isSold, 'is-on-request': isOnRequest })}>
                            <span className={'desktop-only'}>
                                {isSold ? 'Looking for this item? Or want to sell us yours? Get in touch!' : 'Questions about this item?'}
                            </span>
                            <span className={'mobile-only'}>{isSold ? 'Looking for this item? Or want to sell us yours? Get in touch!' : 'Questions about this item?'}</span>

                            <div className="contact-buttons">
                                <i className="fas fa-envelope" onClick={() => window.open(`mailto:contact@zogoedalsoud.com?subject=Item question&body=${whatsappMessage}`)} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612?text=${whatsappMessage}`)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-detail-container">
                    <div className="item-details">
                        <p>Item details</p>
                        <div>
                            <label>SKU</label>
                            <span>{product.sku}</span>
                        </div>
                        {!isEmpty(designer) && (
                            <div>
                                <label>Designer</label>
                                <DesignerNavigation attributes={product.attributes}>
                                    <span>{designer}</span>
                                </DesignerNavigation>
                            </div>
                        )}
                        {!isEmpty(producer) && (
                            <div>
                                <label>Producer</label>
                                <span>{producer}</span>
                            </div>
                        )}
                        {!isEmpty(year) && (
                            <div>
                                <label>Year</label>
                                <span>{year}</span>
                            </div>
                        )}
                        {!isEmpty(period) && (
                            <div>
                                <label>Period</label>
                                <span>{period}</span>
                            </div>
                        )}
                        {!isEmpty(origin) && (
                            <div>
                                <label>Origin</label>
                                <span>{origin}</span>
                            </div>
                        )}
                        {!isEmpty(material) && (
                            <div>
                                <label>Material</label>
                                <span>{material}</span>
                            </div>
                        )}
                        {!isEmpty(productColor) && (
                            <div>
                                <label>Color</label>
                                <span>{productColor}</span>
                            </div>
                        )}
                        {product.dimensions.length && (
                            <div>
                                <label>Length</label>
                                <span>{product.dimensions.length} cm</span>
                            </div>
                        )}
                        {product.dimensions.width && (
                            <div>
                                <label>Width</label>
                                <span>{product.dimensions.width} cm</span>
                            </div>
                        )}
                        {product.dimensions.height && (
                            <div>
                                <label>Height</label>
                                <span>{product.dimensions.height} cm</span>
                            </div>
                        )}
                        {!isEmpty(condition) && (
                            <div>
                                <label>Condition</label>
                                <span>{condition}</span>
                            </div>
                        )}

                        {!isEmpty(seatingHeight) && (
                            <div>
                                <label>Seat Height</label>
                                <span>{seatingHeight} cm</span>
                            </div>
                        )}
                        {!isEmpty(seatingDepth) && (
                            <div>
                                <label>Seat depth</label>
                                <span>{seatingDepth} cm</span>
                            </div>
                        )}
                        {!isEmpty(seatingWidth) && (
                            <div>
                                <label>Seat width</label>
                                <span>{seatingWidth} cm</span>
                            </div>
                        )}

                        {!isEmpty(tableBottomHeight) && (
                            <div>
                                <label>Table bottom height</label>
                                <span>{tableBottomHeight} cm</span>
                            </div>
                        )}
                    </div>
                    <div className="item-story">
                        <p>About this item</p>
                        <div className={'pre-wrapped-text'} dangerouslySetInnerHTML={{ __html: description }} />
                        <br />
                        <p>{getAttribute(product.attributes, 'designer')}</p>
                        <div className={'pre-wrapped-text'}>{designerInfo}</div>
                    </div>
                </div>
                <br />
                <br />
                <div className="content-block">
                    <h2 className="shop-latest recently-viewed">Related Items</h2>
                    <div>
                        <div className="designer-products related-items">
                            {__relatedProducts && <ShopLatestProducts items={__relatedProducts} seeMoreItems={false} showNameAndPrice={true} onNavigate={onNavigate} />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Product;
