import LOADING_IMAGE from '../../../assets/loadingImage_1.svg';

export const DEFAULT_PRODUCT = {
    id: 'loading',
    name: 'retrieving product..',
    price: 'loading',
    images: [{ src: LOADING_IMAGE }, { src: LOADING_IMAGE }, { src: LOADING_IMAGE }, { src: LOADING_IMAGE }, { src: LOADING_IMAGE }],
    attributes: [],
    description: '',
    related_ids: [],
    dimensions: {},
};
