// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { routes } from './routes/routes';
import Framework from './core/Framework';
import React from 'react';
import { Helmet } from 'react-helmet';
import redirects from './redirects';

const PageNotFound = () => (
    <>
        <Helmet>
            <title>404 Not Found</title>
            <description>This page or product does not exist</description>
            <meta name="robots" content="noindex, follow" />
            <meta name="prerender-status-code" content="404" />
        </Helmet>
        <div style={{ margin: 'auto', width: '300px', textAlign: 'center', paddingTop: '20px' }}>
            <h1>404</h1>
            <div>You were redirected to this 404 page, because the page or product you requested does not exist.</div>
            <br />
            <div>Maybe you found this page on Google, Bing or Yahoo? This is our new website and the old page does not exist anymore. You are welcome exploring our new site!</div>
            <br />
            <a href="https://www.zogoedalsoud.com">
                <img src="https://shop.zogoedalsoud.com/sdImages/ZGAO-online-shop-for-vintage-design-furniture-lighting-and-accessories.svg" alt="Logo of Zo Goed Als Oud" />
            </a>
        </div>
    </>
);

function Container() {
    return (
        <Switch>
            {/* Redirects */}
            {redirects.map(({ from, to }, index) => (
                <Redirect key={index} exact from={from} to={to} status={301} />
            ))}

            {/* Product Redirects - 11-08-2021 */}
            <Route exact path="/accessories/:subcategory/:product/" render={props => <Redirect to={`/other/${props.match.params.subcategory}/${props.match.params.product}`} />} status={301} />
            <Route exact path="/accessories/coatracks/:product/" render={props => <Redirect to={`/other/coatracks/${props.match.params.product}`} />} status={301} />
            <Route exact path="/accessories/kids/:product/" render={props => <Redirect to={`/other/kids/${props.match.params.product}`} />} status={301} />
            <Route exact path="/accessories/magazine-stands/:product/" render={props => <Redirect to={`/other/magazine-stands/${props.match.params.product}`} />} status={301} />
            <Route exact path="/accessories/mirrors/:product/" render={props => <Redirect to={`/other/mirrors/${props.match.params.product}`} />} status={301} />
            <Route exact path="/accessories/other/:product/" render={props => <Redirect to={`/other/miscellaneous/${props.match.params.product}`} />} status={301} />

            {/* Routes */}
            {routes.map(({ path, component }, index) => (
                <Route key={index} exact component={Framework(component)} path={path} />
            ))}

            {/* Fallbacks */}
            <Route path={'/404'} component={Framework(PageNotFound)} status={404} />
            <Route path={'*'} component={Framework(PageNotFound)} status={404} />
        </Switch>
    );
}
export default withRouter(Container);
