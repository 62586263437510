// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

// Utils
import favorites from '../../core/favorites-storage';

// Styling
import './Header.scss';

class Header extends React.Component {
    state = {
        searchInput: '',
    };
    onSearchClick = () => {
        if (this.state.searchInput === '') return;

        ReactGA.event({
            category: 'User',
            action: 'Searched Product',
            label: this.state.searchInput,
        });

        if (this.state.searchInput === 'sale') {
            this.props.onNavigate({ url: '/sale' });
            return;
        }

        this.props.onNavigate({ url: `/search?result=${this.state.searchInput}`, search: this.state.searchInput });
    };

    // 13 is code for 'ENTER'
    checkEnterPressed = e => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            this.onSearchClick();
        }
    };

    render() {
        return (
            <div className="main-logo">
                <NavLink to={'/'} className={'main-logo-container'}>
                    <img alt="Logo-for-ZGAO-online-shop-for-vintage-design-furniture-lighting-and-accessories" src={this.props.image} />
                </NavLink>

                <div className={'search-input-container'} aria-label={'searchbar'} >
                    <input
                        placeholder={'Search our collection...'}
                        value={this.state.searchInput}
                        onKeyUp={this.checkEnterPressed}
                        onChange={e => this.setState({ searchInput: e.target.value })}
                    />
                    <button type={'button'} onClick={this.onSearchClick} aria-label={'search'}>
                        <i className={'fa fa-search'} />
                    </button>
                </div>
                <div className={'favorites-list'} onClick={() => this.props.openModal(10)}>
                    <div className={'show-list-button'}>
                        <i className={'fa fa-heart'} /> <label>{this.props.countFavorites || favorites.count()}</label>
                    </div>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    image: PropTypes.string.isRequired,
    countFavorites: PropTypes.number,
};

export default Header;
