// Modules
import React from 'react';
import i18n from 'i18n-react/dist/i18n-react';
import { isNil } from 'lodash';

// Utils
import { buildNavLinkUrl } from '../utils/utils';
import { ProductTile } from '../../routes/products/components/Products';

class ShopLatestProducts extends React.Component {
    render() {
        const { seeMoreItems, items, onNavigate } = this.props;

        return (
            <div className="product-tiles shop-latest-container">
                {!isNil(items) &&
                    (items || []).map((item, index) => {
                        // If item is undefined, we cant show it...
                        if (isNil(item)) return null;

                        const url = buildNavLinkUrl(item);

                        // If we want to show see more items tile, return out of the last loop index
                        if (seeMoreItems && index === items.length - 1) return null;

                        return <ProductTile key={item.id + '-' + index} item={item} category={''} onNavigate={() => onNavigate({ url })} />;
                    })}
                {seeMoreItems && (
                    <div className="product-tile see-more-items" key={'see-more'} onClick={() => onNavigate({ url: '/new' })}>
                        <span>{i18n.translate('seeMoreItems')}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default ShopLatestProducts;
