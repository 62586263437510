// Modules
import { connect } from 'react-redux';

// Components
import Products from '../components/Products';

// Selectors
import { selectCurrentRoutePage, selectProductsFromState, selectRouteInformationFromState } from '../../home/modules/selectors';
import { getProducts, updateRoute } from '../../home/modules/actionCreators';

const mapStateToProps = (state, ownProps) => {
    let dataType = ownProps.match.url;

    if (ownProps.location.pathname === '/search') {
        dataType = ownProps.location.pathname + ownProps.location.search;
    }

    const currentPage = selectCurrentRoutePage(state, dataType);
    const routeInfo = selectRouteInformationFromState(state, dataType, currentPage);

    return {
        products: selectProductsFromState(state, dataType, currentPage),
        productsLoading: routeInfo.status === 'loading',
        historyObject: ownProps.history,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onNavigate: ({ url, categoryId }) => {
        dispatch(getProducts({ categoryId, dataType: url }));
        ownProps.history.push(url);
    },
    getProducts: ({ url, categoryId, page, search }) => {
        dispatch(updateRoute({ dataType: url, page }));
        dispatch(getProducts({ categoryId, dataType: url, page, search }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
