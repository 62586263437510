// Modules
import { connect } from 'react-redux';

// Selectors
import { selectFavorites } from '../routes/home/modules/selectors';

// Components
import FavoritesList from './FavoritesList';

const mapStateToProps = (state, ownProps) => {
    return { allFavoritesFromStore: selectFavorites(state) };
};

const mapDispatchToProps = {
    triggerFavoriteUpdate: item => ({ type: 'TRIGGER_FAVORITES_UPDATE', payload: { item } }),
    setCount: count => ({ type: 'SET_COUNT_FAVORITES', payload: { count } }),
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesList);
