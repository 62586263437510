// Modules
import { fork, put } from 'redux-saga/effects';

// Sagas
import {
    getProductsSaga,
    getProductSaga,
    getRelatedProductsSaga,
    getDesignersSaga,
    getFeaturedProductsSaga,
    getShopLatestProductsSaga,
    getProductsByDesignerSaga,
} from '../routes/home/modules/sagas';

// ActionCreators
import { getDesigners, getFeaturedProducts, getShopLatestProducts } from '../routes/home/modules/actionCreators';

/**
 * This is the Main saga function, where we fork all sagas of the application.
 *
 */
export function* rootSaga() {
    // for logging purposes...
    // yield takeEvery('*', logActionType);

    // Registering All Core Sagas
    yield fork(getProductsSaga);
    yield fork(getProductSaga);
    yield fork(getDesignersSaga);
    yield fork(getFeaturedProductsSaga);
    yield fork(getShopLatestProductsSaga);
    yield fork(getRelatedProductsSaga);
    yield fork(getProductsByDesignerSaga);

    // Initial data loading: the actions are dispatched everytime the application is initialized:

    // Load the designers
    yield put(getDesigners({ page: 1 }));
    yield put(getDesigners({ page: 2 }));

    // Get the Featured Products
    yield put(getFeaturedProducts());

    // Get the Shop Latest Products
    yield put(getShopLatestProducts());

}

// const logActionType = action => console.debug(' *** Action is dispatched now! *** ', action);
