// Modules
import { concat } from 'lodash';

// Routes
import homeRoutes from './home/routes';
import productsRoutes from './products/routes';
import productRoutes from './product/routes';
import customerServiceRoutes from './customer-service/routes';
import designerRoutes from './designer/routes';
import designersRoutes from './designers/routes';
import mobileCategoryRoutes from './mobile-category/routes';

export const routes = concat(homeRoutes, mobileCategoryRoutes, customerServiceRoutes, designerRoutes, designersRoutes, productRoutes, productsRoutes);
