// Modules
import React from 'react';
import ImageGallery from 'react-image-gallery';

// Utils
import { makeThumbnailSource } from './Carousel';

// Assets
import ASK_FOR_IMAGE from '../../assets/image-coming-soon.svg';

// Styling
import 'react-image-gallery/styles/css/image-gallery.css';

export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIndex: false,
            showBullets: false,
            infinite: true,
            showThumbnails: true,
            showFullscreenButton: false,
            showGalleryFullscreenButton: true,
            showPlayButton: false,
            showGalleryPlayButton: false,
            showNav: false,
            isRTL: false,
            slideDuration: 450,
            slideInterval: 2000,
            thumbnailPosition: 'right',
            showVideo: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.slideInterval !== prevState.slideInterval || this.state.slideDuration !== prevState.slideDuration) {
            // refresh setInterval
            this._imageGallery.pause();
            this._imageGallery.play();
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.id !== this.props.id;
    }

    _onImageClick(event) {
        // console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
    }

    _onImageLoad(event) {
        // console.debug('loaded image', event.target.src);
    }

    _onSlide(index) {
        // console.debug('slid to index', index);
    }

    _onPause(index) {
        // console.debug('paused on index', index);
    }

    _onScreenChange(fullScreenElement) {
        // console.debug('isFullScreen?', !!fullScreenElement);
    }

    _onPlay(index) {
        // console.debug('playing from index', index);
    }

    render() {
        const { images: __images } = this.props;
        const images = __images.length === 0 ? [{ src: ASK_FOR_IMAGE }, { src: ASK_FOR_IMAGE }, { src: ASK_FOR_IMAGE }, { src: ASK_FOR_IMAGE }, { src: ASK_FOR_IMAGE }] : __images;

        return (
            <section className="app">
                <ImageGallery
                    ref={i => (this._imageGallery = i)}
                    items={images.map(image => ({
                        original: makeThumbnailSource(image.src, true),
                        thumbnail: makeThumbnailSource(image.src, true),
                        originalClass: 'featured-slide',
                        thumbnailClass: 'featured-thumb',
                        originalAlt: image.name,
                        thumbnailAlt: image.name + '-thumbnail',
                    }))}
                    index={this.props.index}
                    lazyLoad={true}
                    onClick={this._onImageClick.bind(this)}
                    onImageLoad={this._onImageLoad}
                    onSlide={this._onSlide.bind(this)}
                    onPause={this._onPause.bind(this)}
                    onScreenChange={this._onScreenChange.bind(this)}
                    onPlay={this._onPlay.bind(this)}
                    infinite={this.state.infinite}
                    showBullets={this.state.showBullets}
                    showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                    showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                    showThumbnails={this.state.showThumbnails}
                    showIndex={this.state.showIndex}
                    showNav={this.state.showNav}
                    isRTL={this.state.isRTL}
                    thumbnailPosition={this.state.thumbnailPosition}
                    slideDuration={parseInt(this.state.slideDuration, 10)}
                    slideInterval={parseInt(this.state.slideInterval, 10)}
                    additionalClass="app-image-gallery"
                    onImageError={event => {
                        event.target.src = ASK_FOR_IMAGE;
                    }}
                    onThumbnailError={event => {
                        event.target.src = ASK_FOR_IMAGE;
                    }}
                />
            </section>
        );
    }
}
