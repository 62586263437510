// Module
import React from 'react';
import { NavLink } from 'react-router-dom';
import { replace } from 'lodash';

// Styles
import './BreadCrumb.css';
import './BreadCrumbMobile.scss';

const renderBreadCrumbUrl = string => replace(string, /-/g, ' ');

const BreadCrumb = props => {
    const {
        match: { url },
    } = props;

    const splittedURL = url.split('/');

    let breadCrumbONE = splittedURL[1];
    // let breadCrumbTWO = splittedURL[2]; // uncomment for testing purposes..
    // let breadCrumbTHREE = splittedURL[3]; // uncomment for testing purposes..

    const isMobile = window.innerWidth < 768;

    const mobileCategoryValues = ['tables', 'seating', 'lighting', 'storage', 'other'];

    if (isMobile && mobileCategoryValues.includes(breadCrumbONE)) {
        breadCrumbONE = 'category/' + breadCrumbONE;
    }

    return (
        <h3 className="bread-crumb" style={{ textTransform: 'uppercase', textAlign: 'center' }}>
            <span>
                <NavLink to={'/'}>HOME</NavLink>
            </span>

            {!isMobile && splittedURL[1] && splittedURL[1] !== 'category' && (
                <span>
                    {' | '}
                    <NavLink to={'/' + breadCrumbONE}>{renderBreadCrumbUrl(splittedURL[1])}</NavLink>
                </span>
            )}

            {!isMobile && splittedURL[2] && splittedURL[1] !== 'designer' && (
                <span>
                    {' | '}
                    <NavLink to={'/' + breadCrumbONE + '/' + splittedURL[2]}>{renderBreadCrumbUrl(splittedURL[2])}</NavLink>
                </span>
            )}

            {isMobile && splittedURL[1] && splittedURL[1] !== 'category' && (
                <span>
                    {' | '}
                    <NavLink to={'/' + breadCrumbONE}>{renderBreadCrumbUrl(splittedURL[1])}</NavLink>
                </span>
            )}

            {isMobile && splittedURL[2] && splittedURL[1] !== 'designer' && (
                <span>
                    {' | '}
                    <NavLink to={'/' + splittedURL[1] + '/' + splittedURL[2]}>{renderBreadCrumbUrl(splittedURL[2])}</NavLink>
                </span>
            )}
        </h3>
    );
};

export default BreadCrumb;
