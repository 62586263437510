import React from 'react';
import Slider from 'react-slick';
import { isEmpty, isNil } from 'lodash';
import { NavLink } from 'react-router-dom';

// Styling
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import './Carousel.css';

// Utils
import { buildNavLinkUrl } from '../utils/utils';

// Assets
import LOADING_IMAGE from '../../assets/loadingImage_1.svg';
import ASK_FOR_IMAGE from '../../assets/image-coming-soon.svg';
import {ImageComponent} from '../../routes/products/components/Products';

/**
 * Carousel
 * @documentation https://github.com/akiran/react-slick
 * @documentation https://react-slick.neostack.com/docs/example/resizable
 */
const Carousel = ({ images }) => {
    const settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <SliderArrow to="prev" />,
        nextArrow: <SliderArrow to="next" />,
    };

    const sources = isEmpty(images) ? [LOADING_IMAGE, LOADING_IMAGE, LOADING_IMAGE, LOADING_IMAGE, LOADING_IMAGE] : images.map(item => item.images[0].src);
    const names = isEmpty(images)
        ? [
              'selecting our items..',
              'finding our favorites..',
              'retrieving our collection..',
              'looking for the past..',
              'getting vintage design..',
          ]
        : images.map(item => item.name);

    return (
        <div className="carousel-wrapper">
            <Slider {...settings}>
                {sources.map((item, i) => (
                    <div key={i}>
                        <div style={{ border: '5px solid #fff' }}>
                            <div className="carousel-image-container">
                                <NavLink to={buildNavLinkUrl(images[i])}>
                                    <ImageComponent height="100%" src={isEmpty(images) ? LOADING_IMAGE : makeThumbnailSource(item)} alt={names[i]} />
                                </NavLink>
                            </div>
                            <div className="carousel-text-container">
                                <NavLink to={buildNavLinkUrl(images[i])}>
                                    <span>{names[i]}</span>
                                </NavLink>
                                <div className="shop-now-button">
                                    <NavLink to={buildNavLinkUrl(images[i])}>SHOP NOW</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;

export function makeThumbnailSource(source, big = false) {
    if (source === LOADING_IMAGE) return LOADING_IMAGE;
    if (source === ASK_FOR_IMAGE) return ASK_FOR_IMAGE;
    if (isNil(source)) return ASK_FOR_IMAGE;

    // Most images should be .jpg files
    let fileType = '.jpg';

    // We could also possibly be a .png file:
    if (source.indexOf('.png') !== -1) {
        fileType = '.png';
    }

    const [urlName] = source.split(fileType);

    const thumbnailSize = big ? '-1024x768' : '-400x300';

    return urlName + thumbnailSize + fileType;
}

// Custom arrow component
const SliderArrow = ({ className, to, onClick }) =>
    to === 'prev' ? (
        <div onClick={onClick} className="carousel-arrow-left">
            <span className={'oi oi-caret-left'} />
        </div>
    ) : (
        <div onClick={onClick} className="carousel-arrow-right">
            <span className={'oi oi-caret-right'} />
        </div>
    );
