// Containers
import ProductsContainer from './containers/ProductsContainer';

const productsPaths = [
    '/all',
    '/new',
    '/sale',
    '/sold',
    '/search',
    '/seating',
    '/seating/lounge-chairs',
    '/seating/dining-chairs',
    '/seating/office-chairs',
    '/seating/sofas',
    '/seating/stools-and-ottomans',
    '/seating/beds-and-daybeds',
    '/tables',
    '/tables/coffee-tables',
    '/tables/desks',
    '/tables/dining-tables',
    '/tables/nesting-tables',
    '/tables/side-tables',
    '/tables/trolleys-and-bar-carts',
    '/storage',
    '/storage/shelf-and-wall-systems',
    '/storage/chests-of-drawers',
    '/storage/sideboards',
    '/storage/highboards',
    '/storage/nightstands',
    '/storage/wardrobes',
    '/lighting',
    '/lighting/ceiling-and-pendant-lights',
    '/lighting/desk-and-table-lights',
    '/lighting/floor-lights',
    '/lighting/wall-lights-and-sconces',
    '/other',
    '/other/outdoor',
    '/other/coatracks',
    '/other/kids',
    '/other/magazine-stands',
    '/other/miscellaneous',
    '/other/mirrors',
];

export default productsPaths.map(path => ({
    path,
    component: ProductsContainer,
}));
