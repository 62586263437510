// Modules
import { connect } from 'react-redux';

// Components
import Footer from './Footer';

// Selectors
import { selectResultByDataType, selectStatusByDataType } from '../routes/home/modules/selectors';
import { SHOP_LATEST_PRODUCTS } from '../routes/home/modules/actionCreators';

const mapStateToProps = state => {
    // show the LATEST_PRODUCTS in recently viewed, if localstorage is empty
    return {
        recentlyViewed: selectStatusByDataType(state, SHOP_LATEST_PRODUCTS) === 'success' ? selectResultByDataType(state, SHOP_LATEST_PRODUCTS).data : [],
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onNavigate: ({ url }) => {
        ownProps.history.push(url);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
