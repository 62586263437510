// Modules
import React from 'react';
import { NavLink } from 'react-router-dom';
import { isUndefined } from 'lodash';
import i18n from 'i18n-react';

// Menu
import { menu } from './menu';

// Components
import { LinkComponent } from '../../@shared/components/LinkComponent';

// Styling
import './Navigation.css';

//Assets
//Lighting
import catFloorLight from '../../assets/category/lighting/shop-authentic-mid-century-vintage-design-floor-light-and-other-furniture-online.jpg';
import catWallLight from '../../assets/category/lighting/shop-authentic-mid-century-vintage-design-wall-light-and-other-furniture-online.jpg';
import catDeskLight from '../../assets/category/lighting/shop-authentic-mid-century-vintage-design-table-and-desk-light-and-other-furniture-online.jpg';
import catPendantLight from '../../assets/category/lighting/shop-authentic-mid-century-vintage-design-ceiling-and-pendant-and-other-furniture-online.jpg';

//Seating
import catDiningChair from '../../assets/category/seating/shop-authentic-mid-century-vintage-design-dining-chairs-online.jpg';
import catOfficeChair from '../../assets/category/seating/shop-authentic-mid-century-vintage-design-office-chairs-online.jpg';
import catLoungeChair from '../../assets/category/seating/shop-authentic-mid-century-vintage-design-lounge-chairs-online.jpg';
import catOttoman from '../../assets/category/seating/shop-authentic-mid-century-vintage-design-stools-and-ottomans-online.jpg';
import catSofa from '../../assets/category/seating/shop-authentic-mid-century-vintage-design-sofas-online.jpg';
import catDaybed from '../../assets/category/seating/shop-authentic-mid-century-vintage-design-beds-and-daybeds-online.jpg';

//Storage
import catSideboard from '../../assets/category/storage/shop-authentic-mid-century-vintage-design-sideboard-and-highboard-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catHighboard from '../../assets/category/storage/shop-authentic-mid-century-vintage-design-highboards-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catNightstand from '../../assets/category/storage/shop-authentic-mid-century-vintage-design-nightstands-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catChestDrawers from '../../assets/category/storage/shop-authentic-mid-century-vintage-design-chest-of-drawers-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catWardrobe from '../../assets/category/storage/shop-authentic-mid-century-vintage-design-wardrobe-and-other-furniture-online-at-zo-goed-als-oud.jpg';
import catWallSystem from '../../assets/category/storage/shop-authentic-mid-century-vintage-design-wall-system-and-other-furniture-online-at-zo-goed-als-oud.jpg';

//Tables
import catDiningTable from '../../assets/category/tables/shop-authentic-mid-century-vintage-design-dining-tables-and-other-furniture-online.jpg';
import catCoffeeTable from '../../assets/category/tables/shop-authentic-mid-century-vintage-design-coffee-tables-and-other-furniture-online.jpg';
import catNestingTable from '../../assets/category/tables/shop-authentic-mid-century-vintage-design-nesting-tables-and-other-furniture-online.jpg';
import catSideTable from '../../assets/category/tables/shop-authentic-mid-century-vintage-design-side-tables-and-other-furniture-online.jpg';
import catTrolley from '../../assets/category/tables/shop-authentic-mid-century-vintage-design-trolley-and-bar-carts-and-other-furniture-online.jpg';
import catDesk from '../../assets/category/tables/shop-authentic-mid-century-vintage-design-desks-and-other-furniture-online.jpg';

//Accessoiries
import catMirror from '../../assets/category/accessories/shop-authentic-mid-century-vintage-design-mirrors-and-other-furniture-online.jpg';
import catCoatrack from '../../assets/category/accessories/shop-authentic-mid-century-vintage-design-coatracks-and-other-furniture-online.jpg';
import catKids from '../../assets/category/accessories/shop-authentic-mid-century-vintage-design-kids-items-and-other-furniture-online.jpg';
import catArt from '../../assets/category/accessories/shop-authentic-mid-century-vintage-design-art-and-other-furniture-online.jpg';
import catMagazineStands from '../../assets/category/accessories/shop-authentic-mid-century-vintage-design-magazine-stands-and-other-furniture-online.jpg';
import catOther from '../../assets/category/accessories/shop-authentic-mid-century-vintage-design-unique-items-online.jpg';

const Navigation = props => {
    const subcategoryTilesData = {
        28: [
            { url: '/seating/lounge-chairs', source: catLoungeChair, slug: 'Lounge' },
            { url: '/seating/dining-chairs', source: catDiningChair, slug: 'Dining' },
            { url: '/seating/office-chairs', source: catOfficeChair, slug: 'Office' },
            { url: '/seating/sofas', source: catSofa, slug: 'Sofa' },
            { url: '/seating/stools-and-ottomans', source: catOttoman, slug: 'Stool & Ottoman' },
            { url: '/seating/beds-and-daybeds', source: catDaybed, slug: 'Bed & Daybed' },
        ],
        42: [
            { url: '/tables/coffee-tables', source: catCoffeeTable, slug: 'Coffee' },
            { url: '/tables/desks', source: catDesk, slug: 'Desk' },
            { url: '/tables/dining-tables', source: catDiningTable, slug: 'Dining' },
            { url: '/tables/nesting-tables', source: catNestingTable, slug: 'Nesting' },
            { url: '/tables/side-tables', source: catSideTable, slug: 'Side' },
            { url: '/tables/trolleys-and-bar-carts', source: catTrolley, slug: 'Trolley & Cart' },
        ],
        35: [
            { url: '/storage/shelf-and-wall-systems', source: catWallSystem, slug: 'Shelving system' },
            { url: '/storage/chests-of-drawers', source: catChestDrawers, slug: 'Chest of drawers' },
            { url: '/storage/sideboards', source: catSideboard, slug: 'Sideboard' },
            { url: '/storage/highboards', source: catHighboard, slug: 'Highboard' },
            { url: '/storage/nightstands', source: catNightstand, slug: 'Nightstand' },
            { url: '/storage/wardrobes', source: catWardrobe, slug: 'Wardrobe' },
        ],
        23: [
            { url: '/lighting/ceiling-and-pendant-lights', source: catPendantLight, slug: 'Ceiling & Pendant' },
            { url: '/lighting/desk-and-table-lights', source: catDeskLight, slug: 'Desk & Table' },
            { url: '/lighting/floor-lights', source: catFloorLight, slug: 'Floor' },
            { url: '/lighting/wall-lights-and-sconces', source: catWallLight, slug: 'Wall & Sconce' },
        ],
        16: [
            { url: '/other/outdoor', source: catArt, slug: 'Outdoor' },
            { url: '/other/coatracks', source: catCoatrack, slug: 'Coatrack' },
            { url: '/other/kids', source: catKids, slug: 'Kids' },
            { url: '/other/magazine-stands', source: catMagazineStands, slug: 'Magazine stand' },
            { url: '/other/mirrors', source: catMirror, slug: 'Mirror' },
            { url: '/other/miscellaneous', source: catOther, slug: 'Miscellaneous' },
        ],
    };

    return (
        <div id="menu-wrapper">
            <ul className="nav">
                {menu.map(({ url, label, submenu, modal, modalId, categoryId }, index) => {
                    let submenuImages = [];
                    if (categoryId) {
                        submenuImages = subcategoryTilesData[categoryId];
                    }

                    return (
                        <React.Fragment key={index + url}>
                            <li>
                                {/* @note -->  https://stackoverflow.com/questions/5292343/is-an-anchor-tag-without-the-href-attribute-safe */}
                                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                {!isUndefined(modal) && <a onClick={() => props.openModal(modalId)}>{label}</a>}
                                {isUndefined(modal) && <LinkComponent url={url} label={label} onClick={() => props.onNavigate({ url, categoryId })} />}
                                {!isUndefined(submenu) && (
                                    <div>
                                        <div className="nav-column">
                                            <p>{i18n.translate(`${label}Title`)}</p>
                                            <div style={{ display: 'flex', paddingTop: '10px' }}>
                                                <ul style={{ width: '50%' }}>
                                                    {submenu
                                                        .filter((item, i) => i < 3)
                                                        .map(({ url, label, categoryId }, index) => (
                                                            <li key={label + index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                <i className={'fa fa-caret-right'} style={{ paddingRight: '3px' }} />
                                                                <LinkComponent
                                                                    url={url}
                                                                    onClick={() => {
                                                                        props.onNavigate({ url, categoryId });
                                                                    }}
                                                                    label={label}
                                                                />
                                                            </li>
                                                        ))}
                                                </ul>
                                                <ul style={{ width: '50%' }}>
                                                    {submenu
                                                        .filter((item, i) => i >= 3)
                                                        .map(({ url, label, categoryId }, index) => (
                                                            <li key={label + index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                {index !== submenu.filter((item, i) => i >= 3).length - 1 && (
                                                                    <i className={'fa fa-caret-right'} style={{ paddingRight: '3px' }} />
                                                                )}
                                                                <LinkComponent
                                                                    url={url}
                                                                    onClick={() => {
                                                                        props.onNavigate({ url, categoryId });
                                                                    }}
                                                                    label={label}
                                                                />
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="nav-column-preview-images">
                                            {submenuImages.map(item => (
                                                <NavLink to={item.url} key={item.url}>
                                                    <img style={{ width: '100px', padding: '4px' }} src={item.source} title={item.slug} alt={item.slug} />
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </li>
                            <li className={'stripe'} />
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

export default Navigation;
