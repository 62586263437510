// Modules
import { isNil } from 'lodash';

export function disableBodyScroll() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
}

export function enableBodyScroll() {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'scroll';
}

export function fadeInFrameworkContent() {
    const element = document.getElementsByClassName('framework-content')[0];
    element.className = 'framework-content fade-in';
}

export function fadeOutFrameworkContent() {
    const element = document.getElementsByClassName('framework-content')[0];
    element.className = 'framework-content fade-out';
}

export function getCanonicalUrl() {
    // If the location object is not available, return
    if (isNil(window.location)) return false;

    const { pathname, origin, search } = window.location;

    const lastPathnameCharacter = pathname.charAt(pathname.length - 1);
    const isCanonical = lastPathnameCharacter === '/';

    let canonicalUrl = false;

    if (isCanonical) {
        const slicedPathname = pathname.slice(0, -1);
        canonicalUrl = origin + slicedPathname + search;
    }

    return canonicalUrl;
}
