import { getProducts, openModal } from '../../routes/home/modules/actionCreators';

// Modules
import { connect } from 'react-redux';

// Selectors
import { selectProductsFromState } from '../../routes/home/modules/selectors';

// Components
import Navigation from './Navigation';

// Utils
import { disableBodyScroll } from '../utils';

const mapStateToProps = (state) => {
    return {
        menuSeating: selectProductsFromState(state, '/seating', 1),
        menuTables: selectProductsFromState(state, '/tables', 1),
        menuStorage: selectProductsFromState(state, '/storage', 1),
        menuLights: selectProductsFromState(state, '/lighting', 1),
        menuAccessories: selectProductsFromState(state, '/other', 1),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    openModal: modalId => {
        dispatch(openModal(modalId));
        disableBodyScroll();
    },
    onNavigate: ({ url, categoryId }) => {
        dispatch(getProducts({ categoryId, dataType: url }));
        ownProps.history.push(url);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
