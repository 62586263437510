// Modules
import { connect } from 'react-redux';

// Components
import Designers from '../components/Designers';

// Selectors
import { selectCurrentRoutePage, selectDesigners, selectRouteInformationFromState } from '../../home/modules/selectors';
import { getDesigners, updateRoute } from '../../home/modules/actionCreators';

const mapStateToProps = state => {
    let numPages = 1;
    let totalItems = 0;

    const currentPage = selectCurrentRoutePage(state, 'designers');

    const routeInfo = selectRouteInformationFromState(state, 'designers', currentPage);
    if (routeInfo.status === 'success') {
        totalItems = routeInfo.result.headers['x-wp-total'];
        numPages = routeInfo.result.headers['x-wp-totalpages'];
    }

    const designers = selectDesigners(state, currentPage);

    return {
        designers,
        numPages,
        totalItems,
        page: currentPage,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateRoute: ({ page }) => {
        dispatch(updateRoute({ dataType: 'designers', page }));
        dispatch(getDesigners({ page }));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Designers);
