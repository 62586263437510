import { getProducts, openModal } from '../../routes/home/modules/actionCreators';

// Modules
import { connect } from 'react-redux';

// Components
import Hamburger from './Hamburger';
import {disableBodyScroll, enableBodyScroll, fadeOutFrameworkContent} from '../utils';

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    openModal: modalId => {
        dispatch(openModal(modalId));
        // fadeOutFrameworkContent();
        disableBodyScroll();
    },
    onNavigate: ({ url, categoryId, search }) => {
        fadeOutFrameworkContent();
        enableBodyScroll();
        dispatch(getProducts({ categoryId, dataType: url, search }));
        ownProps.history.push(url);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hamburger);
