// Modules
import React from 'react';

// Styling
import './Pagination.scss';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: props.numPages,
        };
    }
    render() {
        const { page, totalItems, numPages, onPreviousClick, onNextClick } = this.props;

        return (
            <div className={'pagination'}>
                <button aria-label="Pagination left" disabled={page === 1 || totalItems <= 24} onClick={onPreviousClick}>
                    <i className={'fa fa-caret-left'} />
                </button>
                <div>
                    Page: {page} / {this.props.numPages}
                </div>
                <button aria-label="Pagination right" disabled={page >= numPages} onClick={onNextClick}>
                    <i className={'fa fa-caret-right'} />
                </button>
            </div>
        );
    }
}

export default Pagination;
