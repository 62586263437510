export const menu = [
    { url: '/new', label: 'New' },
    { url: '/all', label: 'All' },
    {
        url: '/seating',
        label: 'Seating',
        categoryId: '28',
        submenu: [
            { url: '/seating/beds-and-daybeds', label: 'Beds and Daybeds', categoryId: '34' },
            { url: '/seating/dining-chairs', label: 'Dining Chairs', categoryId: '29' },
            {
                url: '/seating/lounge-chairs',
                label: 'Lounge Chairs',
                categoryId: '30',
            },
            { url: '/seating/office-chairs', label: 'Office Chairs', categoryId: '32' },
            { url: '/seating/sofas', label: "Sofa's", categoryId: '31' },
            {
                url: '/seating/stools-and-ottomans',
                label: 'Stools and Ottomans',
                categoryId: '33',
            },
            { url: '/seating', label: 'Show All seating', categoryId: '28' },
        ],
    },
    {
        url: '/tables',
        label: 'Tables',
        categoryId: '42',
        submenu: [
            {
                url: '/tables/coffee-tables',
                label: 'Coffee Tables',
                categoryId: '44',
            },
            {
                url: '/tables/desks',
                label: 'Desks',
                categoryId: '45',
            },
            {
                url: '/tables/dining-tables',
                label: 'Dining Tables',
                categoryId: '43',
            },
            {
                url: '/tables/nesting-tables',
                label: 'Nesting Tables',
                categoryId: '46',
            },
            {
                url: '/tables/side-tables',
                label: 'Side Tables',
                categoryId: '47',
            },
            {
                url: '/tables/trolleys-and-bar-carts',
                label: 'Trolleys and Bar Carts',
                categoryId: '48',
            },
            {
                url: '/tables',
                label: 'Show All Tables',
                categoryId: '42',
            },
        ],
    },
    {
        url: '/storage',
        label: 'Storage',
        categoryId: '35',
        submenu: [

            {
                url: '/storage/chests-of-drawers',
                label: 'Chests of Drawers',
                categoryId: '39',
            },
            {
                url: '/storage/highboards',
                label: 'Highboards',
                categoryId: '37',
            },
            {
                url: '/storage/nightstands',
                label: 'Nightstands',
                categoryId: '40',
            },
            {
                url: '/storage/shelf-and-wall-systems',
                label: 'Shelf & Wall Systems',
                categoryId: '38',
            },
            {
                url: '/storage/sideboards',
                label: 'Sideboards',
                categoryId: '36',
            },
            {
                url: '/storage/wardrobes',
                label: 'Wardrobes',
                categoryId: '41',
            },
            {
                url: '/storage',
                label: 'Show All Storage',
                categoryId: '35',
            },
        ],
    },
    {
        url: '/lighting',
        label: 'Lighting',
        categoryId: '23',
        submenu: [
            {
                url: '/lighting/ceiling-and-pendant-lights',
                label: 'Ceiling and Pendant Lights',
                categoryId: '24',
            },
            {
                url: '/lighting/desk-and-table-lights',
                label: 'Desk and Table Lights',
                categoryId: '26',
            },
            {
                url: '/lighting/floor-lights',
                label: 'Floor Lights',
                categoryId: '25',
            },
            {
                url: '/lighting/wall-lights-and-sconces',
                label: 'Wall Lights & Sconces',
                categoryId: '27',
            },
            {
                url: '/lighting',
                label: 'Show All Lighting',
                categoryId: '23',
            },
        ],
    },
    {
        url: '/other',
        label: 'Other',
        categoryId: '16',
        submenu: [
            {
                url: '/other/outdoor',
                label: 'Outdoor',
                categoryId: 17,
            },
            {
                url: '/other/coatracks',
                label: 'Coatracks',
                categoryId: '18',
            },
            {
                url: '/other/kids',
                label: 'Kids',
                categoryId: '19',
            },
            {
                url: '/other/magazine-stands',
                label: 'Magazine Stands',
                categoryId: '20',
            },
            {
                url: '/other/mirrors',
                label: 'Mirrors',
                categoryId: '92',
            },
            {
                url: '/other/miscellaneous',
                label: 'Miscellaneous',
                categoryId: '22',
            },
            {
                url: '/other',
                label: 'Show All Other',
                categoryId: '16',
            },
        ],
    },
    { url: '/sale', label: 'Sale' },
    { url: '/sold', label: 'Sold' },
    { url: '/customer-info', label: 'Info' },
    { url: '/visit', label: 'Visit us', modal: true, modalId: 1 },
    { url: '/contact', label: 'Contact us', modal: true, modalId: 2 },
];
