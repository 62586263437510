// Modules
import React from 'react';
import { capitalize, isString, isUndefined, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

// Styling
import './Designer.css';

// Utils
import { getProductUrl, ProductTile, routeToId } from '../../products/components/Products';
import { getCanonicalUrl } from '../../../core/utils';

class Designer extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.designerId !== this.props.designerId) {
            this.props.onProductsByDesigner({ designerId: this.props.designerId });
        }
    }

    componentDidMount() {
        this.props.onProductsByDesigner({ designerId: this.props.designerId });
    }

    render() {
        const { designersResult, productsFromThisDesigner, onNavigate } = this.props;

        if (isUndefined(designersResult)) {
            return (
                <div className="page-content">
                    <div className="content-block">
                        <h2>Designer</h2>
                        getting designer info..
                    </div>
                </div>
            );
        }
        if (!isString(designersResult.slug)) {
            return (
                <div className="page-content">
                    <div className="content-block">
                        <h2>Designer</h2>
                        getting designer info..
                    </div>
                </div>
            );
        }

        const designerName = designersResult.slug
            .replace(/_/gi, '-')
            .split('-')
            .map((item, index, total) => {
                const lastIndex = total.length - 1;
                if (index !== 0 && index !== lastIndex) {
                    return ` ${item} `;
                }
                return `${capitalize(item)} `;
            })
            .toString()
            .replace(/,/gi, '')
            .trim();

        const structuredDataDesigner = [
            {
                '@context': 'https://schema.org',
                '@type': 'Person',
                name: designerName,
                description: isEmpty(designersResult.description) ? `Shop vintage desgin items by ${designerName} online at Zo Goed als Oud` : designersResult.description,
                image:
                    'https://shop.zogoedalsoud.com/sdImages/shop-your-vintage-designer-from-our-collection-of-furniture-lighting-and-accessories-at-zo-goed-als-oud-online-shop.jpg',
                url: window.location.href,
            },
            {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: [
                    { '@type': 'ListItem', position: 1, item: { '@type': 'Thing', name: 'Home', '@id': 'https://www.zogoedalsoud.com' } },
                    { '@type': 'ListItem', position: 2, item: { '@type': 'Thing', name: 'Designer', '@id': 'https://www.zogoedalsoud.com/designer' } },
                    { '@type': 'ListItem', position: 3, item: { '@type': 'Person', name: designerName, '@id': window.location.href } },
                ],
            },
        ];

        const canonicalUrl = getCanonicalUrl();

        return (
            <div className="page-content designer-page small-margin-top">
                <Helmet>
                    <title>Buy items by {designerName} at Zo Goed Als Oud</title>
                    <meta name="description" content={`Vintage design by ${designerName} for sale online at Zo Goed Als Oud`} />
                    {canonicalUrl && <link rel={'canonical'} href={canonicalUrl} />}

                    <meta property="og:title" content={`Buy items by ${designerName} at Zo Goed Als Oud`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta
                        property="og:image"
                        content="https://shop.zogoedalsoud.com/sdImages/shop-your-vintage-designer-from-our-collection-of-furniture-lighting-and-accessories-at-zo-goed-als-oud-online-shop.jpg"
                    />
                    <meta property="og:image:alt" content={`Buy items by ${designerName} at Zo Goed Als Oud"`} />
                    <meta property="og:site_name" content="Zo Goed Als Oud" />
                    <meta property="og:description" content={`Vintage design pieces by ${designerName} for sale online at Zo Goed Als Oud`} />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={`Buy items by ${designerName} at Zo Goed Als Oud`} />
                    <meta name="twitter:url" content={window.location.href} />
                    <meta name="twitter:description" content={`Vintage design pieces by ${designerName} for sale online at Zo Goed Als Oud`} />
                    <meta name="twitter:url" content={window.location.href} />
                    <meta
                        name="twitter:image"
                        content="https://shop.zogoedalsoud.com/sdImages/shop-your-vintage-designer-from-our-collection-of-furniture-lighting-and-accessories-at-zo-goed-als-oud-online-shop.jpg"
                    />
                    <meta name="twitter:image:alt" content={`Buy items by ${designerName} at Zo Goed Als Oud"`} />

                    <script type="application/ld+json">{JSON.stringify(structuredDataDesigner)}</script>
                </Helmet>

                <div className="content-block">
                    <h2 className="designer-name">{designerName}</h2>
                    <div className="designer-description">{designersResult.description}</div>
                    <br />
                    <br />
                    <br />

                    <h2 className="designer-title">Our {designerName} items</h2>

                    <div className="designer-products product-tiles">
                        {productsFromThisDesigner.map(item => {
                            const url = getProductUrl(item);

                            // We try to get the categoryId based of the url
                            const splittedUrl = url.split('/');
                            const route = splittedUrl.length === 4 ? '/' + splittedUrl[1] + '/' + splittedUrl[2] : '/' + splittedUrl[1];
                            const categoryId = routeToId[route];

                            return <ProductTile key={item.id} item={item} category={{ id: categoryId }} onNavigate={onNavigate} />;
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Designer;
