import api from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { FETCH_API_ERROR, FETCH_API_LOADING, FETCH_API_SUCCESS, UPDATE_DESIGNERS_BY_SLUG_STATE, UPDATE_PRODUCTS_BY_SLUG_STATE } from './constants';

// Utils
import { selectRouteInformationFromState } from '../../routes/home/modules/selectors';

const headers = {
    headers: {
        Authorization: 'Basic Y2tfMGNiMDI3YmE1YzA4NGI5MjQ1NTg1MmJkZmZlNWI1NWU2YzZjOThlMDpjc19lYzAzYTlhNjgwNzNkYzVlMzNkZjVjZmMwMTRiZTBjZWVhMjM1ZDU3',
    },
};

/**
 * Saga that handles GET requests
 * @param action
 * @returns {IterableIterator<*|CallEffect>}
 */
export function* fetchApiGET(action) {
    const { url, dataType, page } = action;

    // Pas geen chaching toe op de volgende items -->
    const nocache = dataType === 'productsByDesigner' || dataType === 'relatedProducts';

    // @todo, check if we have data in state already, if we have, dont do a call and return whats in state
    const caching = yield select(state => selectRouteInformationFromState(state, dataType, page));
    if (caching.status === 'success' && !nocache) {
        yield put({
            type: FETCH_API_SUCCESS,
            payload: caching.result,
            dataType,
            page,
        });
        return { action, result: caching.result };
    } else {
        try {
            yield put({ type: FETCH_API_LOADING, dataType, page });

            const result = yield call(api.get, url, headers);
            yield put({
                type: FETCH_API_SUCCESS,
                payload: result,
                dataType,
                page,
            });

            // console.log("INFO 1: ",  {url, dataType, page});
            // console.log("INFO 2: ",  result);
            // Set total in totalState:
            //             totalState: {
            //                 seating: 233,
            //                 tables: 109,
            //             }


            // If we were products call
            // update the state with productsReducer productBySlug // { 'art-deco-wood-and-fabric-slipper-salon-or-lounge-chair-circa-1930s-france' : { id: 34, ...} }
            if (dataType !== 'designers' && dataType !== 'categories') {
                yield put({ type: UPDATE_PRODUCTS_BY_SLUG_STATE, payload: result });
            }
            if (dataType === 'designers') {
                yield put({ type: UPDATE_DESIGNERS_BY_SLUG_STATE, payload: result });
            }

            return { action, result };
        } catch (error) {
            yield put({ type: FETCH_API_ERROR, payload: error, dataType });
        }
    }
}
