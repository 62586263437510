// Modules
import { connect } from 'react-redux';

// Components
import Designer from '../components/Designer';

// Selectors
import { selectDesignerBySlug, selectProductsByDesigner } from '../../home/modules/selectors';
import { getProducts, getProductsByDesigner } from '../../home/modules/actionCreators';

const mapStateToProps = (state, ownProps) => {
    const {
        match: {
            params: { designer: slug },
        },
    } = ownProps;

    const designer = selectDesignerBySlug(state, slug);
    let designerId = undefined;
    let productsFromThisDesigner = [];
    if (designer) {
        designerId = designer.id;
        productsFromThisDesigner = selectProductsByDesigner(state);
    }

    return {
        designerId,
        designersResult: designer,
        productsFromThisDesigner,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onNavigate: ({ url, categoryId }) => {
        dispatch(getProducts({ categoryId, dataType: url }));
        ownProps.history.push(url);
    },
    onProductsByDesigner: ({ designerId }) => {
        dispatch(getProductsByDesigner({ designerId }));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Designer);
