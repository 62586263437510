// Favorites storage key
const FAVORITES_KEY = 'favorites';

// Return the persisted storage
function getPersistedStorage() {
    return localStorage;
}

function getFavorites() {
    const store = getPersistedStorage();
    const itemFromStorage = store.getItem(FAVORITES_KEY);
    return !!itemFromStorage ? JSON.parse(store.getItem(FAVORITES_KEY)) : [];
}

/* Exportable function */

// Get a specific item form the list
function get(product) {
    const favorites = getFavorites();
    if (!getFavorites()) return;

    const { id } = product;

    // find the specific item
    return favorites.find(item => parseInt(item.id, 10) === parseInt(id, 10));
}

// Add a product to the list
function add(product) {
    const { id, url, image, name } = product;

    const store = getPersistedStorage();
    const favorites = getFavorites();

    favorites.push({ id, url, image, name });
    store.setItem(FAVORITES_KEY, JSON.stringify(favorites));
}

// Remove a product from the list
function remove(product) {
    const { id } = product;
    const store = getPersistedStorage();
    const favorites = getFavorites();
    const newFavorites = favorites.filter(item => parseInt(item.id, 10) !== parseInt(id, 10));
    store.setItem(FAVORITES_KEY, JSON.stringify(newFavorites));
}

// Clear the list
function clear() {
    const store = getPersistedStorage();
    store.removeItem(FAVORITES_KEY);
}

function count() {
    const favorites = getFavorites();
    return favorites.length;
}

const favoriteStorage = { get, add, remove, count, clear, getFavorites };

export default favoriteStorage;
