// Modules
import React, { Component } from 'react';
import { isNil, get } from 'lodash';
import Modal from 'react-awesome-modal';
import favorites from '../../core/favorites-storage';

// Components
import ZGAOForm from '../../@shared/components/ZGAOForm';
import FormInput from '../../@shared/components/FormInput';
import SubmitButton from '../../@shared/components/SubmitButton';

// Utils
import { enableBodyScroll } from '../utils';

// Styling
import './ZGAOModal.css';
import './ZGAOModalMobile.scss';
import FavoritesList from '../FavoritesListContainer';

export class ZGAOModal extends Component {
    state = {
        formState: 'initial', // initial, success, error,loading
    };

    closeModal(modalId) {
        this.setState({ formState: 'initial' });
        enableBodyScroll();
        this.props.closeModal(modalId);
    }

    onSuccessForm = () => {
        this.setState({ formState: 'success' });
    };

    onErrorForm = () => {
        this.setState({ formState: 'error' });
    };

    onLoadingForm = () => {
        this.setState({ formState: 'loading' });
    };

    render() {
        const isVisible = !isNil(this.props.modal) && this.props.modal.status === 'open';

        // Use this id to determine wich modal content we want to show
        const modalIdentifier = !isNil(this.props.modal) && this.props.modal.id;

        const extraModalData = get(this.props, ['modal', 'extra'], {});

        const isMobile = window.innerWidth < 769;
        const mobileModalWidth = window.innerWidth - 30;

        // Initial state
        let modalContent = getModalContentById({
            modalId: modalIdentifier,
            onClose: () => this.closeModal(modalIdentifier),
            onSuccess: this.onSuccessForm,
            onError: this.onErrorForm,
            onLoading: this.onLoadingForm,
            extra: extraModalData,
        });

        if (this.state.formState === 'loading') {
            modalContent = getModalContentById({
                modalId: 9,
                onClose: () => this.closeModal(modalIdentifier),
                extra: extraModalData,
            });
        }
        if (this.state.formState === 'error') {
            modalContent = getModalContentById({
                modalId: 7,
                onClose: () => this.closeModal(modalIdentifier),
                extra: extraModalData,
            });
        }
        if (this.state.formState === 'success') {
            modalContent = getModalContentById({
                modalId: 8,
                onClose: () => this.closeModal(modalIdentifier),
                extra: extraModalData,
            });
        }

        return (
            <section className={'zgao-modal'}>
                <Modal visible={isVisible} width={isMobile ? `${mobileModalWidth}` : '500'} effect="fadeInDown" onClickAway={() => this.closeModal(modalIdentifier)}>
                    {modalContent}
                </Modal>
            </section>
        );
    }
}

/**
 * Modal-types by id:
 * ID:  Modal:
 *  1  Visit us
 *  2  Contact us
 *  3  Checkout / Purchase
 *  4  Request Price
 *  5  Request Shipping Route
 *  6  Show us your items
 *  7  Error
 *  8  Success
 *  9  Loading / Disabled
 *  10 Favorites
 */
// Define all modal contents here
export const getModalContentById = ({ modalId, onClose, extra, onSuccess, onError, onLoading }) => {
    const formFeedbackFunctions = { onSuccess, onError, onLoading };

    // Visit us
    if (modalId === 1) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    VISIT US <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <div>
                        Please note that <b> we are open by appointment only </b>. You can contact us to make an appointment for visiting our store. Our store is located at
                        Aalsmeerderdijk 70-sous 1438 AT Oude Meer, The Netherlands.
                    </div>
                    <ZGAOForm url="https://mailer.zogoedalsoud.com/visit-us.php" fields={['Naam', 'Email', 'Telefoon', 'Bericht']} {...formFeedbackFunctions}>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Tell us who you are
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>
                        <h5>
                            <i className="fa fa-caret-right" />
                            How can we contact you?
                        </h5>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'phone'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput name="Email" placeHolder={'email*'} required={true} type={'email'} />
                            </div>
                        </div>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Tell us about your visit
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <textarea placeholder={'message, date and time*'} name={'Bericht'} required={true} />
                            </div>
                        </div>
                        <SubmitButton type={'submit'} text={'Send'} buttonClassName={'float-right'} />
                        <br />
                        <br /> <br />
                        <br />
                        <div className="contact-question modal-version">
                            <div className="contact-buttons">
                                <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612`)} />
                            </div>
                        </div>
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    // Contact Us
    if (modalId === 2) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    CONTACT US <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <div>Please let us know if you have any questions regarding our items or our business. We will respond to your question as soon as possible.</div>
                    <ZGAOForm url="https://mailer.zogoedalsoud.com/contact-us.php" fields={['Naam', 'Email', 'Telefoon', 'Bericht']} {...formFeedbackFunctions}>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Tell us who you are
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>

                        <h5>
                            <i className="fa fa-caret-right" />
                            How can we contact you?
                        </h5>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'phone'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'e-mail*'} name={'Email'} required={true} type={'email'} />
                            </div>
                        </div>
                        <h5>
                            <i className="fa fa-caret-right" />
                            What would you like to know?
                        </h5>

                        <div>
                            <div style={{ width: '100%' }}>
                                <textarea placeholder={'message*'} name={'Bericht'} required={true} />
                            </div>
                        </div>

                        <SubmitButton type={'submit'} text={'Send'} buttonClassName={'float-right'} />

                        <h5>
                            <i className="fa fa-caret-right" />
                            Store address
                        </h5>

                        <div className={'storage-address-footer'}>
                            <p>
                                <b>Open by appointment only</b>
                            </p>
                            <p>Aalsmeerderdijk 70-sous</p>
                            <p>1438 AT Oude Meer</p>
                            <p>The Netherlands</p>
                        </div>

                        <div className="contact-question modal-version">
                            <div className="contact-buttons">
                                <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612`)} />
                            </div>
                        </div>
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    // Checkout
    if (modalId === 3) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    <i className={'fa fa-shopping-cart'} style={{ paddingTop: '5px', paddingRight: '10px' }} /> ORDER ITEM{' '}
                    <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <h3>{extra.name}</h3>
                    <br />
                    <div>Please fill in the form below and we'll contact you to complete your order.</div>
                    <ZGAOForm url="https://mailer.zogoedalsoud.com/purchase-short.php" fields={['Product', 'Naam', 'Telefoon', 'Email', 'Bericht']} {...formFeedbackFunctions}>
                        <h5>
                            <i className="fa fa-caret-right" />
                            How can we contact you?
                        </h5>
                        <input type="hidden" name={'Product'} defaultValue={extra.url} />
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'phone number'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'email*'} name={'Email'} required={true} type={'email'} />
                            </div>
                        </div>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Is there something else we need to know?
                        </h5>

                        <div>
                            <div style={{ width: '100%' }}>
                                <textarea placeholder={'additional information'} name={'Bericht'} />
                            </div>
                        </div>

                        <SubmitButton type={'submit'} text={'Send'} buttonClassName={'float-right'} />
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    // Request Price
    if (modalId === 4) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    REQUEST PRICE <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <h3>{extra.name}</h3>
                    <br />
                    <div>Thank you for showing interest in our item. Please fill in the form below and we'll respond with the price.</div>
                    <ZGAOForm url="https://mailer.zogoedalsoud.com/request-price.php" fields={['Naam', 'Telefoon', 'Email', 'Product']} {...formFeedbackFunctions}>
                        <h5>
                            <i className="fa fa-caret-right" />
                            How can we contact you?
                        </h5>
                        <input type="hidden" name={'Product'} defaultValue={extra.url} />
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'phone number'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'email*'} name={'Email'} required={true} type={'email'} />
                            </div>
                        </div>

                        <SubmitButton type="submit" text={'Send'} buttonClassName={'float-right'} />

                        <br />
                        <br />
                        <br />
                        <br />

                        <div className="contact-question modal-version">
                            <div className="contact-buttons">
                                <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612?text=Hi, could you tell me the price of ${extra.name} ?`)} />
                            </div>
                        </div>
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    // Request Shipping Quote
    if (modalId === 5) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    REQUEST SHIPPING QUOTE <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <h3>{extra.name}</h3>
                    <br />
                    <div>We offer worldwide shipping! Please fill in the form below and we'll respond with the best options.</div>
                    <ZGAOForm
                        url="https://mailer.zogoedalsoud.com/request-shipping-quote.php"
                        fields={['Product', 'Postcode', 'Land', 'Naam', 'Telefoon', 'Email', 'Bericht']}
                        {...formFeedbackFunctions}
                    >
                        <h5>
                            <i className="fa fa-caret-right" />
                            What is your zip code and country?
                        </h5>
                        <input type="hidden" name={'Product'} defaultValue={extra.url} />
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'postal / zip code*'} name={'Postcode'} required={true} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'country*'} name={'Land'} required={true} />
                            </div>
                        </div>

                        <h5>
                            <i className="fa fa-caret-right" />
                            How can we contact you?
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'phone number'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'email*'} name={'Email'} required={true} type={'email'} />
                            </div>
                        </div>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Is there something else we need to know?
                        </h5>

                        <div>
                            <div style={{ width: '100%' }}>
                                <textarea placeholder={'additional information'} name={'Bericht'} />
                            </div>
                        </div>

                        <SubmitButton type={'submit'} text={'Send'} buttonClassName={'float-right'} />
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    // Show items to sell
    if (modalId === 6) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    SELL US YOUR ITEMS <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <div>
                        We can sometimes buy your items. The items we favor are design furniture, lighting, art and accessories from the 20th century. Supply us with info below and
                        we'll respond with an offer within 24 hours.
                    </div>
                    <ZGAOForm url="https://mailer.zogoedalsoud.com/sell-us-items.php" fields={['Bericht', 'Naam', 'Telefoon', 'Email']} {...formFeedbackFunctions}>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Please provide us with some info about your item(s)
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <textarea placeholder={'Information*'} name={'Bericht'} required={true} />
                            </div>
                        </div>

                        <h5>
                            <i className="fa fa-caret-right" />
                            Add some images of your item(s)
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <input type={'file'} required={true} />
                            </div>
                        </div>

                        <h5>
                            <i className="fa fa-caret-right" />
                            How can we contact you?
                        </h5>
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'Phone number'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'Email*'} name={'Email'} required={true} type={'email'} />
                            </div>
                        </div>

                        <div className={'storage-address-footer'}>
                            <p>You can also use whatsapp for sending images or email us directly at <a href="mailto:contact@zogoedalsoud.com">contact@zogoedalsoud.com</a></p>
                        </div>
                        <br />
                        <div className="contact-question modal-version">
                            <div className="contact-buttons" style={{ left: '0', position: 'absolute', bottom: 'unset', top: 'unset', right: 'unset' }}>
                                <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612`)} />
                            </div>
                        </div>

                        <SubmitButton type={'submit'} text={'Send'} buttonClassName={'float-right'} />
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    if (modalId === 7) {
        return (
            <div className="modal-content no-min-height">
                <header onClick={onClose}>
                    Whoops.. <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <div>
                        It looks like something went wrong with sending the form.
                        <br />
                        Please check your internet connection and try again later.
                        <br />
                        <br />
                        Email us at <a href="mailto:contact@zogoedalsoud.com">contact@zogoedalsoud.com</a>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Or contact us by
                        </h5>
                        <div className="contact-question modal-version">
                            <div className="contact-buttons">
                                <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612`)} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    if (modalId === 8) {
        return (
            <div className="modal-content no-min-height">
                <header onClick={onClose}>
                    Thank you! The form has been sent. <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <div>
                        We'll respond to your message as soon as possible.
                        <br />
                        <br />
                        If you have placed an order, we'll contact you as soon as possible to complete your order. We will then provide you with payment details and arrange
                        shipping.
                        <h5>
                            <i className="fa fa-caret-right" />
                            You could also contact us by
                        </h5>
                        <div className="contact-question modal-version">
                            <div className="contact-buttons">
                                <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                                <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                                <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612`)} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    if (modalId === 9) {
        return (
            <div className="modal-content">
                <header onClick={onClose}>Sending the form</header>
                <section>Sending the form...</section>
            </div>
        );
    }

    if (modalId === 10) {

        const allFavorites = favorites.getFavorites();
        const formattedForForm = allFavorites.map(item => item.url);

        return (
            <div className="modal-content">
                <header onClick={onClose}>
                    Favorites <i className={'fa fa-times'} style={{ float: 'right', paddingTop: '5px', paddingRight: '10px' }} />
                </header>
                <section>
                    <div>Your list of favorites. You can use this to request a quote for multiple items.</div>
                    <FavoritesList allFavorites={allFavorites} />

                    <ZGAOForm url="https://mailer.zogoedalsoud.com/request-favorites-quote.php" fields={['Naam', 'Telefoon', 'Email', 'Favorites']} {...formFeedbackFunctions}>
                        <h5>
                            <i className="fa fa-caret-right" />
                            Request quote
                        </h5>
                        <input type="hidden" name={'Favorites'} value={JSON.stringify(formattedForForm)} />
                        <div>
                            <div style={{ width: '100%' }}>
                                <FormInput placeHolder={'name*'} name={'Naam'} required={true} />
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'phone number'} name={'Telefoon'} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormInput placeHolder={'email*'} name={'Email'} required={true} type={'email'} />
                            </div>
                        </div>

                        <SubmitButton type="submit" text={'Send'} buttonClassName={'float-right'} />

                        <br />
                        <br />
                        <br />
                    </ZGAOForm>
                </section>
            </div>
        );
    }

    return <div>There is no modal id specified</div>;
};
