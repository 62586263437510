export const cserviceMenuItems = [
    "cserviceAbout",
    "cserviceOrdening",
    "cserviceVisiting",
    "cserviceTerms",
    "cserviceReturning",
    "cserviceShipping"
    
    
];

