// Modules
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { FETCH_API_ERROR, FETCH_API_LOADING, FETCH_API_SUCCESS, UPDATE_DESIGNERS_BY_SLUG_STATE, UPDATE_PRODUCTS_BY_SLUG_STATE, UPDATE_ROUTE } from './api/constants';
import dataTransformer from './dataTransformer';
import { keyBy } from 'lodash';

/**
 * The state structure for this application:
 *  => state
 *      => data
 *          => [dataType]
 *              => status
 *              => items
 *      => components
 *          => [componentName]
 *              => {}
 *      => routing
 */

/**
 * A Basic Reducer Function
 * Reducers are functions that update the state / store with new data
 * @param state
 * @param action
 * @returns {*}
 */
function dataStateReducer(state, action) {
    // Make a shallow copy of state
    const newState = { ...state };

    let { payload, dataType, page } = action;

    switch (action.type) {
        case FETCH_API_LOADING:
            if (page) {
                newState[dataType] = {
                    ...newState[dataType],
                    [page]: {
                        status: 'loading',
                    },
                };
            } else {
                newState[dataType] = {
                    status: 'loading',
                };
            }
            break;

        case FETCH_API_SUCCESS:
            if (page) {
                newState[dataType][page] = {
                    status: 'success',
                    result: payload,
                    transformed: dataTransformer(dataType, payload),
                };
            } else {
                newState[dataType] = {
                    status: 'success',
                    result: payload,
                    transformed: dataTransformer(dataType, payload),
                };
            }

            break;

        case UPDATE_PRODUCTS_BY_SLUG_STATE:
            newState['productsBySlug'] = {
                ...state.productsBySlug,
                ...keyBy(payload.data, item => item.slug),
            };
            break;

        case UPDATE_DESIGNERS_BY_SLUG_STATE:
            newState['designerBySlug'] = {
                ...state.designerBySlug,
                ...keyBy(payload.data, item => item.name),
            };
            break;

        case UPDATE_ROUTE:
            newState[payload.dataType] = {
                ...newState[payload.dataType],
                currentPage: payload.page,
            };
            break;

        case FETCH_API_ERROR:
            newState[dataType] = {
                status: 'error',
                error: payload,
            };
            break;

        case 'OPEN_MODAL':
            newState.modal = {
                id: payload.modalId,
                status: 'open',
                extra: payload.extra,
            };
            break;

        case 'CLOSE_MODAL':
            newState.modal = {
                id: payload.modalId,
                status: 'closed',
            };
            break;

        case 'SET_COUNT_FAVORITES':
            newState.countFavorites = action.payload.count;
            break;

        case 'TRIGGER_FAVORITES_UPDATE':
            newState.favoritesTriggered = new Date();
            break;


        case 'ADD_FAVORITE':
            break;

            case 'REMOVE_FAVORITE':
            break;


        default:
            return newState;
    }

    // Caching
    // if (action.updateCache) {
    //     localStorage.setItem("cache", JSON.stringify(newState));
    // }

    return newState;
}

/**
 * Export all reducers of the application as 1 combined reducers object
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
export const reducers = combineReducers({
    routing: routerReducer,
    data: dataStateReducer,
    components: {},
});
