import React from 'react';
import './MadeBy.scss';
const MadeBy = () => {
    return (
        <div className="made-by">
            <a href="https://www.shappo.app" rel="noopener noreferrer" target="_blank" aria-label={'Storefront powered by Shappo'}>
                Storefront powered by Shappo
            </a>
            {' '}-{' '}
            <a href="https://www.zogoedalsoud.com/legal/terms-and-conditions-zo-goed-als-oud.pdf" rel="nofollow noindex noopener noreferrer" target="_blank" aria-label={'Terms and Conditions'}>
                Terms and Conditons
            </a>
        </div>
    );
};

export default MadeBy;
