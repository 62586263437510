import { isNil } from 'lodash';
import {
    GET_DESIGNERS,
    GET_FEATURED_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCTS_BY_DESIGNER,
    GET_RELATED_PRODUCTS,
    GET_SHOP_LATEST_PRODUCTS,
    UPDATE_ROUTE,
} from '../../../services/api/constants';

// dataTypes
export const PRODUCTS = 'products';
export const DESIGNERS = 'designers';
export const FEATURED_PRODUCTS = 'featuredProducts';
export const SHOP_LATEST_PRODUCTS = 'shopLatestProducts';

// Correct ActionCreators
export const getProducts = ({ page, limit, categoryId, dataType, search }) => {
    const pageParam = isNil(page) ? 1 : page;
    const limitParam = isNil(limit) ? 24 : limit;
    let url = `/wc/v3/products?status=publish&stock_status=instock&page=${pageParam}&per_page=${limitParam}`;
    if (categoryId) {
        url = `/wc/v3/products?status=publish&stock_status=instock&page=${pageParam}&per_page=${limitParam}&category=${categoryId}`;
    }
    if (dataType === '/sale') {
        url = `/wc/v3/products?status=publish&stock_status=instock&page=${pageParam}&per_page=${limitParam}&on_sale=true`;
    }
    if (dataType === '/sold') {
        url = `/wc/v3/products?status=publish&page=${pageParam}&per_page=${limitParam}&stock_status=outofstock`;
    }
    if (search) {
        url = `/wc/v3/products?status=publish&per_page=96&search=${search}`;
    }

    return {
        type: GET_PRODUCTS,
        payload: {
            url: url,
            dataType: isNil(dataType) ? PRODUCTS : dataType,
            page: pageParam,
        },
    };
};

export const getProduct = ({ slug }) => {
    const url = `/wc/v3/products?slug=${slug}`;
    return { type: GET_PRODUCT, payload: { url, dataType: slug } };
};

export const updateRoute = ({ dataType, page }) => {
    return {
        type: UPDATE_ROUTE,
        payload: { dataType, page },
    };
};

export const getDesigners = ({ page }) => {
    return {
        type: GET_DESIGNERS,
        payload: {
            url: `/wc/v3/products/attributes/3/terms?page=${page}&per_page=100`,
            dataType: DESIGNERS,
            page,
        },
    };
};

export const getProductsByDesigner = ({ designerId }) => {
    return {
        type: GET_PRODUCTS_BY_DESIGNER,
        payload: {
            url: `/wc/v3/products?status=publish&attribute=pa_designer&attribute_term=${designerId}&per_page=24`,
            dataType: 'productsByDesigner',
        },
    };
};

export const getFeaturedProducts = () => ({
    type: GET_FEATURED_PRODUCTS,
    payload: {
        url: '/wc/v3/products?status=publish&featured=true&page=1&per_page=15',
        dataType: FEATURED_PRODUCTS,
    },
});

export const getShopLatestProducts = () => ({
    type: GET_SHOP_LATEST_PRODUCTS,
    payload: { url: '/wc/v3/products?status=publish&page=1&per_page=5', dataType: SHOP_LATEST_PRODUCTS },
});

export const getRelatedProducts = ids => {
    const url = '/wc/v3/products?include=' + ids.toString();
    return { type: GET_RELATED_PRODUCTS, payload: { url, dataType: 'relatedProducts' } };
};

export const openModal = (modalId, extra) => {
    return {
        type: 'OPEN_MODAL',
        payload: {
            modalId,
            title: 'test modal',
            extra,
        },
    };
};

export const closeModal = modalId => {
    return {
        type: 'CLOSE_MODAL',
        payload: {
            modalId,
        },
    };
};
