export const loadingProducts = [
    {
        id: 0,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 1,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 2,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 3,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 4,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 5,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 6,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 7,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 8,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 9,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 10,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 11,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 12,
        images: 'loading',
        name: 'searching our collection....',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 13,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 14,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
    {
        id: 15,
        images: 'loading',
        name: 'searching our collection..',
        price: 'loading',
        categories: [],
        attributes: [],
    },
];

export const filterCategories = ['Seating', 'Tables', 'Storage', 'Lighting', 'Accessories'];

export const materials = ['wood', 'fabric', 'metal', 'leather', 'synthetic', 'stone', 'glass'];

export const filterColors = [
    {
        name: 'brown',
        hex: '#754C24',
    },
    {
        name: 'black',
        hex: '#000000',
    },
    {
        name: 'dark-grey',
        hex: '#808080',
    },
    {
        name: 'light-grey',
        hex: '#F2F2DC',
    },
    {
        name: 'red',
        hex: '#FF0000',
    },
    {
        name: 'orange',
        hex: '#F7931E',
    },
    {
        name: 'blue',
        hex: '#0071BC',
    },
    {
        name: 'purple',
        hex: '#93278F',
    },
    {
        name: 'white',
        hex: '#ffffff',
    },
    {
        name: 'pink',
        hex: '#FF7BAC',
    },
    {
        name: 'gold',
        hex: '#AD9964',
    },
    {
        name: 'yellow',
        hex: '#FFFF00',
    },
    {
        name: 'green',
        hex: '#009245',
    },
    {
        name: 'gray',
        hex: '#CCCCCC',
    },
];
