import React from 'react';
import { isFunction } from 'lodash';

export const LinkComponent = props => {
    const otherProps = {
        className: props.className,
    };

    return (
        <a
            href={props.url}
            onClick={e => {
                e.preventDefault();
                isFunction(props.onClick) && props.onClick();
            }}
            {...otherProps}
        >
            {props.label || props.children}
        </a>
    );
};
