import { transformCategories } from "./transformers/categories";

const dataTransformer = (dataType, data) => {
    // The collection of all transform functions of the application
    // binded to a dataType
    const transformers = {
        categories: transformCategories(data)
    };

    return transformers[dataType];
};

export default dataTransformer;
