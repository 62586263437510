// Modules
import React from 'react';
import i18n from 'i18n-react';

// Styling
import './CookieBanner.scss';

class CookieBanner extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hidden: this.isCookieInstalled(),
		};
	}

	componentDidMount() {
		// did we already accept?
		if (this.isCookieInstalled()) {
			console.log('We hebben cookies dus gebruiker heeft al eerder accepted dus we mogen initilizen');
			this.props.accept();
		}
	}

	// expired cookies get deleted by the browser automatically
	isCookieInstalled = () => !!document.cookie;

	closeBanner = () => {
		this.setState({ hidden: true });
	};

	render() {
		// No banner if cookie is installed already
		if (this.state.hidden) {
			return <></>;
		}
		const { accept } = this.props;

		return (
			<article className={'md-cookie-banner'}>
				<header>
					<p>Cookies</p>
				</header>
				<main>
					{i18n.translate('cookieBannerText')}
				</main>
				<footer>
					<button
						onClick={() => {
							accept();
							this.closeBanner();
						}}
					>
						{i18n.translate('cookieBannerAccept')}
					</button>
					<span
						className={'reject-button'}
						onClick={() => {
							this.closeBanner();
						}}
					>
						{i18n.translate('cookieBannerReject')}
					</span>
				</footer>
			</article>
		);
	}
}

export default CookieBanner;
