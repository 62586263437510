import { isUndefined } from "lodash";

export const transformCategories = data => {
    const categories =
        !isUndefined(data) && data.data.filter(item => item.parent === 0);

    const subCategories =
        !isUndefined(data) && data.data.filter(item => item.parent !== 0);

    return {
        categories,
        subCategories
    };
};
