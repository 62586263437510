// Modules
import { connect } from "react-redux";

// Components
import CustomerService from "../components/CustomerService";

// Selectors
import { selectCompleteReduxStore } from "../../home/modules/selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        reduxStore: selectCompleteReduxStore(state)
    };
};

export default connect(
    mapStateToProps,
    {}
)(CustomerService);
