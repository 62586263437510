// Modules
import React from 'react';
import ImageGallery from 'react-image-gallery';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';

// Utils
import { makeThumbnailSource } from './Carousel';
import { buildNavLinkUrl } from '../utils/utils';

// Styling
import 'react-image-gallery/styles/css/image-gallery.css';
import './MobileGallery.scss';

import ASK_FOR_IMAGE from '../../assets/image-coming-soon.svg';
import LOADING_IMAGE from '../../assets/loadingImage_1.svg'


class GalleryMobile extends React.Component {
    state = {
        mobileSliderIndex: 0,
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.id !== this.props.id || nextState.mobileSliderIndex !== this.state.mobileSliderIndex;
    }

    render() {
        const { products, renderProductName } = this.props;

        const { items: __items } = this.props;
        const items = __items.length === 0 ? [{ src: LOADING_IMAGE }, { src: LOADING_IMAGE }, { src: LOADING_IMAGE }, { src: LOADING_IMAGE }, { src: LOADING_IMAGE }] : __items;

        return (
            <>
                <div className={'mobile-gallery'}>
                    <ImageGallery
                        onClick={event => {
                            // ...
                        }}
                        onSlide={currentIndex => this.setState({ mobileSliderIndex: currentIndex })}
                        showNav={false}
                        showBullets={true}
                        thumbnailPosition={'right'}
                        infinite={true}
                        showPlayButton={false}
                        showGalleryPlayButton={false}
                        showFullscreenButton={false}
                        startIndex={this.state.mobileSliderIndex}
                        items={(items || []).map(image => ({
                            original: makeThumbnailSource(image.src, true),
                            thumbnail: makeThumbnailSource(image.src, true),
                            originalClass: 'featured-slide',
                            thumbnailClass: 'featured-thumb',
                            originalAlt: image.name,
                        }))}
                        onImageError={event => {
                            event.target.src = ASK_FOR_IMAGE;
                        }}
                        onThumbnailError={event => {
                            event.target.src = ASK_FOR_IMAGE;
                        }}
                    />
                </div>
                {renderProductName && (
                    <div className="product-name">
                        {!isEmpty(products) && <NavLink to={buildNavLinkUrl(products[this.state.mobileSliderIndex])}>{products[this.state.mobileSliderIndex].name}</NavLink>}
                    </div>
                )}
            </>
        );
    }
}

export default GalleryMobile;
