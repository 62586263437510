// Modules
import React from 'react';
import JsPDF from 'jspdf';

// Styling
import './FavoritesList.scss';

// Utils
import favorites from './favorites-storage';

class FavoritesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allFavorites: props.allFavorites,
        };
    }

    componentDidMount() {
        this.setState({ allFavorites: favorites.getFavorites() });
    }

    static getDerivedStateFromProps(props, state) {
        return { ...state, allFavorites: favorites.getFavorites() };
    }

    printToPDF = () => {
        const { allFavorites } = this.state;
        const doc = new JsPDF();
        doc.setFontSize(10);
        doc.text(10, 20, 'A list of your favorite products from www.zogoedalsoud.com');
        doc.text(10, 25, 'You can send it to contact@zogoedalsoud.com to request a quote');
        doc.text(10, 30, 'Click on an item in this list to visit the item page');
        doc.text(10, 35, 'For any questions call us at +31625218612 or email us at contact@zogoedalsoud.com');
        doc.text(10, 40, ' ');

        let stepCount = -1;
        let pageCount = 1;

        allFavorites.map((item, index) => {
            stepCount++;
            if (stepCount === 24) {
                pageCount++;
                stepCount = 0;
                doc.addPage();
            }

            let topMargin = 50;
            if (pageCount > 1) {
                topMargin = 20;
            }

            const displayText = `${index + 1}. ${item.name}`;
            const _x = 10;
            const _y = topMargin + stepCount * 10;

            return doc.textWithLink(displayText, _x, _y, { url: item.url });
        });

        doc.save(`Favorites_${new Date().valueOf()}.pdf`);
    };

    render() {
        const { allFavorites } = this.state;
        return (
            <div className={'favorites-list-modal'}>
                <div className={'download-as-pdf'} onClick={this.printToPDF}>
                    <i className={'fa fa-download'} /> Download as PDF
                </div>

                <ul>
                    {allFavorites.length === 0 && (
                        <li style={{ paddingLeft: '0.5em' }}>
                            <label>Use <span style={{ color: '#AAB8C2' }}>&#10084;</span> to add items</label>
                        </li>
                    )}
                    {allFavorites.map(item => (
                        <li key={item.id}>
                            <div
                                className={'remove-from-list'}
                                onClick={() => {
                                    favorites.remove(item);
                                    this.setState({ allFavorites: favorites.getFavorites() });
                                    this.props.triggerFavoriteUpdate(item);
                                    this.props.setCount(this.state.allFavorites.length - 1);
                                }}
                            >
                                <i className={'fa fa-times'} />
                            </div>
                            <a href={item.url}>
                                <img src={item.image} alt={item.name} />
                            </a>
                            <a href={item.url}>
                                <label>{item.name}</label>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
export default FavoritesList;
