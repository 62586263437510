import MobileCategoryContainer from './containers/MobileCategoryContainer';

export default [
    {
        path: '/category/:category',
        component: MobileCategoryContainer,
    },
    {
        path: '/category',
        component: MobileCategoryContainer,
    },
];
