// Modules
import { connect } from 'react-redux';

// Components
import Home from '../components/Home';

// Selectors
import { selectStatusByDataType, selectResultByDataType } from '../modules/selectors';

// Constants
import { FEATURED_PRODUCTS, openModal, SHOP_LATEST_PRODUCTS } from '../modules/actionCreators';
import { DEFAULT_PRODUCT } from '../modules/constants';

// Utils
import { disableBodyScroll } from '../../../core/utils';

const mapStateToProps = (state, ownProps) => {
    let highlightedProducts = [];
    let shopLatestProducts = [DEFAULT_PRODUCT, DEFAULT_PRODUCT, DEFAULT_PRODUCT, DEFAULT_PRODUCT, DEFAULT_PRODUCT];

    if (selectStatusByDataType(state, FEATURED_PRODUCTS) === 'success') {
        highlightedProducts = selectResultByDataType(state, FEATURED_PRODUCTS).data;
    }

    if (selectStatusByDataType(state, SHOP_LATEST_PRODUCTS) === 'success') {
        shopLatestProducts = selectResultByDataType(state, SHOP_LATEST_PRODUCTS).data;
    }

    return {
        highlightedProducts,
        shopLatestProducts,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    openModal: modalId => {
        dispatch(openModal(modalId));
        disableBodyScroll();
    },
    onNavigate: ({ url }) => {
        ownProps.history.push(url);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
