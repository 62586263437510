// Modules
import React from 'react';
import ReactGA from 'react-ga';

// Components
import NavigationContainer from './navigation/NavigationContainer';
import HeaderContainer from './header/HeaderContainer';
import HamburgerContainer from './navigation/HamburgerContainer';
import FrameTop from './FrameTop';
import FooterContainer from './FooterContainer';
import BreadCrumb from '../@shared/components/BreadCrumb';

// Assets
import tempLogo from '../assets/ZGAO-online-shop-for-vintage-design-furniture-lighting-and-accessories.svg';
import mobileLogo from '../assets/zo-goed-als-oud-online-shop-for-vintage-furniture-lighting-and-accessories.svg';

// Styling
import './Framework.css';

// Utils
import { enableBodyScroll } from './utils';

/**
 * Framework is a Higher-order Component that is wrapped around page components.
 * @param WrappedComponent
 * @returns {function(*): *}
 * @constructor
 */
const Framework = WrappedComponent => props => {
    // When the framework gets a render, we should always be able to scroll again
    enableBodyScroll();

    if (!!document.cookie) {
        ReactGA.set({ page: window.location.pathname }); // Update the user's current page
        ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    }

    return (
        <div>
            <FrameTop />
            <div className="frame">
                <div className={'desktop-menu'}>
                    <HeaderContainer {...props} image={tempLogo} />
                    <div className="logo-and-menu-container" style={{ display: 'flex' }}>
                        <NavigationContainer {...props} />
                    </div>
                </div>
                <div className={'mobile-menu'}>
                    <div className={'mobile-header'}>
                        <HeaderContainer {...props} image={mobileLogo} />
                        <HamburgerContainer {...props} />
                    </div>
                </div>
                <div className="framework-content">
                    {props.match.path !== '/' && <BreadCrumb {...props} />}
                    <WrappedComponent {...props} />
                </div>
                <FooterContainer {...props} />
            </div>
        </div>
    );
};

export default Framework;
