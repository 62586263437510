// Modules
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';

// Components
import Product from '../components/Product';

// ActionCreators
import { getProduct, openModal } from '../../home/modules/actionCreators';

// Selectors
import { selectProductBySlug, selectRelatedProducts, selectAllDesigners } from '../../home/modules/selectors';

// Utils
import { disableBodyScroll } from '../../../core/utils';

export function updateRecentlyViewed(product) {
    if (isNil(product)) return;

    if (isEmpty(product.images)) return;

    try {
        const currentStorage = localStorage.getItem('recently-viewed');
        const recentlyViewed = [];

        // If storage is clear:
        if (isNil(currentStorage)) {
            recentlyViewed.push(product);
            localStorage.setItem('recently-viewed', JSON.stringify(recentlyViewed));
            return;
        }

        // If we have storage:
        const parsed = JSON.parse(currentStorage).filter((item, index) => index < 5);

        // Just unshift and filter
        if (!isNil(parsed)) {
            const filtered = parsed.filter(item => item.id !== product.id);

            if (filtered.length < 4) {
                filtered.push(product);
            } else {
                filtered.unshift(product);
            }
            localStorage.removeItem('recently-viewed');

            // Check if current item is already in currently viewed, because then you do nothing:
            localStorage.setItem('recently-viewed', JSON.stringify(filtered));
        }
    } catch (e) {
        // Something in storage went wrong, lets reset
        localStorage.removeItem('recently-viewed');
    }
}

const mapStateToProps = (state, ownProps) => {
    const {
        match: {
            params: { product },
        },
    } = ownProps;

    return {
        product: selectProductBySlug(state, product),
        designers: selectAllDesigners(state),
        relatedProducts: selectRelatedProducts(state),
        favoritesTriggered: state.data.favoritesTriggered
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    getProductBySlug: ({ slug }) => {
        dispatch(getProduct({ slug }));
    },
    openModal: (modalId, extra) => {
        dispatch(openModal(modalId, extra));
        disableBodyScroll();
    },
    onNavigate: ({ url }) => {
        ownProps.history.push(url);
    },
    setCountFavorites: count => {
        dispatch({ type: 'SET_COUNT_FAVORITES', payload: { count } });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
