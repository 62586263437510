import { getProducts, openModal } from '../../routes/home/modules/actionCreators';

// Modules
import { connect } from 'react-redux';

// Components
import Header from './Header';

// Utils
import { disableBodyScroll } from '../utils';

const mapStateToProps = state => {
    return {
        state,
        countFavorites: state.data.countFavorites,
        favoritesTriggered: state.data.favoritesTriggered || true,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    openModal: modalId => {
        dispatch(openModal(modalId));
        disableBodyScroll();
    },
    onNavigate: ({ url, search }) => {
        dispatch(getProducts({ dataType: url, search }));
        ownProps.history.push(url);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
