// Modules
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Reducers
import { reducers } from './reducers';

// Saga
import { rootSaga } from './sagas';

// Create and Apply the Middlewares
const sagaMiddleware = createSagaMiddleware();
const middleWare = applyMiddleware(sagaMiddleware);

// The state of the application on start
const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

// Create the Redux Store
const store = createStore(reducers, initialState, composeEnhancers(middleWare));

// Start Sagas
sagaMiddleware.run(rootSaga);

export { store };
