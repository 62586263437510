import { isEmpty, isNil } from 'lodash';

/**
 * This function returns a url to navigate to.
 * The only paramater is the product object.
 * @param product
 * @returns {string}
 */
export const buildNavLinkUrl = product => {
    if (isNil(product)) {
        return '/';
    }

    if (isNil(product.categories)) {
        return '/';
    }

    const collectionOfCategories = ['other', 'tables', 'seating', 'storage', 'lighting'];
    const { categories, slug } = product;
    const result = categories.filter(item => !isEmpty(collectionOfCategories.filter(str => str === item.slug)));

    if (isNil(result[0])) {
        return '/';
    }

    const category = result[0].slug;
    const subcategory = categories.find(item => item.slug !== category);

    // Build the url for the product page:
    return `/${category}/${subcategory.slug}/${slug}`;
};
