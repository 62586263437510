// Modules
import React from 'react';
import i18n from 'i18n-react';

// Styling
import './Framework.css';
import { connect } from 'react-redux';
import { selectCompleteReduxStore } from '../routes/home/modules/selectors';

const FrameTop = props => (
    <div className="frame-top">
        <span>{i18n.translate('frameTopLeft')}</span>
        <span>{i18n.translate('frameTopCenter')}</span>
        <span>{i18n.translate('frameTopRight')}</span>
    </div>
);

const mapStateToProps = (state, ownProps) => {
    return {
        reduxStore: selectCompleteReduxStore(state),
    };
};

export default connect(
    mapStateToProps,
    {}
)(FrameTop);
