import React from 'react';
import { capitalize } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './Designers.css';

import Pagination from '../../../core/Pagination';
import {getCanonicalUrl} from '../../../core/utils';


class Designers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // pagination
            page: 1,
        };
    }

    displayDesignerName = string =>
        string
            .split('-')
            .map(item => `${capitalize(item)} `)
            .join('');

    render() {
        const { designers, page, numPages, totalItems } = this.props;


        const structuredDataDesigners = [

            {
                '@context': 'http://schema.org/',
                '@type': 'WebPage',
                name: 'Designer page', //Naam van de van de category
                image: "https://shop.zogoedalsoud.com/sdImages/shop-your-favorite-20th-century-vintage-designer-at-zo-goed-als-oud-online-shop-for-furniture-lighting-and-accessories.jpg",
                url: window.location.href, //Url van de categorypagina + pagination
                description: 'List of 20th century designers',
            },


            {
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: [
                    { '@type': 'ListItem', position: 1, item: { '@type': 'Thing', name: 'Home', '@id': 'https://zogoedalsoud.com' } },
                    { '@type': 'ListItem', position: 2, item: { '@type': 'Thing', name: 'Designer', '@id': 'https://zogoedalsoud.com/designer' } },
                ],
            },
        ];

        const canonicalUrl = getCanonicalUrl();

        return (
            <div className="page-content small-margin-top">
                <Helmet>
                    <title>Shop your Favorite 20th Century Vintage Designer at Zo Goed Als Oud</title>
                    <meta
                        name="description"
                        content="Buy vintage furniture, lighting and accessories by the most famous designers of 20th century. All authentic and ready for world wide shipping."
                    />
                    {canonicalUrl && <link rel={'canonical'} href={canonicalUrl} />}

                    <meta property="og:title" content="Shop your Favorite 20th Century Vintage Designer at Zo Goed Als Oud" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.zogoedalsoud.com/designer" />
                    <meta property="og:image" content="https://shop.zogoedalsoud.com/sdImages/shop-your-favorite-20th-century-vintage-designer-at-zo-goed-als-oud-online-shop-for-furniture-lighting-and-accessories.jpg" />
                    <meta property="og:image:alt" content="Shop your Favorite 20th Century Vintage Designer at Zo Goed Als Oud" />
                    <meta property="og:site_name" content="Zo Goed Als Oud" />
                    <meta
                        property="og:description"
                        content="Buy vintage furniture, lighting and accessories by the most famous designers of 20th century. All authentic and ready for world wide shipping."
                    />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Shop your Favorite 20th Century Vintage Designer at Zo Goed Als Oud" />
                    <meta
                        name="twitter:description"
                        content="Buy vintage furniture, lighting and accessories by the most famous designers of 20th century. All authentic and ready for world wide shipping."
                    />
                    <meta name="twitter:url" content="https://www.zogoedalsoud.com/designer" />
                    <meta name="twitter:image" content="https://shop.zogoedalsoud.com/sdImages/shop-your-favorite-20th-century-vintage-designer-at-zo-goed-als-oud-online-shop-for-furniture-lighting-and-accessories.jpg" />
                    <meta name="twitter:image:alt" content="Shop your Favorite 20th Century Vintage Designer at Zo Goed Als Oud" />

                    <script type="application/ld+json">{JSON.stringify(structuredDataDesigners)}</script>
                </Helmet>

                <div className="content-block">
                    <h2>Designer</h2>

                    <div className="cservice-list designers-list">
                        {designers.map((item, i) => (
                            <NavLink to={'/designer/' + item.name} key={item.name}>
                                <div key={i} title={this.displayDesignerName(item.name)}>
                                    <i aria-label={'More about ' + item.name} className="fa fa-caret-right" />

                                    {this.displayDesignerName(item.name)}
                                </div>
                            </NavLink>
                        ))}
                    </div>
                    <Pagination
                        totalItems={totalItems}
                        numPages={numPages}
                        page={page}
                        onPreviousClick={() => {
                            this.props.updateRoute({ page: page - 1 });
                            this.setState({ page: page - 1 });
                        }}
                        onNextClick={() => {
                            this.setState({ page: page + 1 });
                            this.props.updateRoute({ page: page + 1 });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default Designers;
