import { get, size, concat } from 'lodash';
import { DEFAULT_PRODUCT } from './constants';

/**
 * Selector: select the api status
 * @param state
 * @returns {string}
 */
export const selectCompleteReduxStore = state => state;

export const selectModalState = state => state.data.modal;

export const selectResultByDataType = (state, dataType) => (state.data[dataType] ? state.data[dataType].result : undefined);
export const selectStatusByDataType = (state, dataType) => (state.data[dataType] ? state.data[dataType].status : undefined);

export const selectAllDesigners = state => {
    const numberOfDesignersPages = size(get(state, ['data', 'designers'], {}));
    let allDesigners = [];

    for (let i = 1; i <= numberOfDesignersPages; i++) {
        // get the designers per page from state
        const designersPerPage = selectDesigners(state, i);
        allDesigners = concat(allDesigners, designersPerPage);
    }

    return allDesigners;
};

export const selectDesigners = (state, page) => {
    return get(state, ['data', 'designers', page, 'result', 'data'], []);
};

export const selectProductsFromState = (state, dataType, page) => {
    if (page) {
        return get(state, ['data', dataType, page, 'result', 'data'], []);
    }
    return get(state, ['data', dataType, 'result', 'data'], []);
};

export const selectRouteInformationFromState = (state, dataType, page) => {
    if (page) {
        return get(state, ['data', dataType, page], {});
    }
    return get(state, ['data', dataType], {});
};

export const selectProductBySlug = (state, slug) => {
    return get(state, ['data', 'productsBySlug', slug], DEFAULT_PRODUCT);
};

export const selectDesignerBySlug = (state, slug) => {
    return get(state, ['data', 'designerBySlug', slug], undefined);
};

export const selectCurrentRoutePage = (state, dataType) => {
    return get(state, ['data', dataType, 'currentPage'], 1);
};

export const selectRelatedProducts = state => {
    return get(state, ['data', 'relatedProducts', 'result', 'data'], []);
};

export const selectProductsByDesigner = state => {
    return get(state, ['data', 'productsByDesigner', 'result', 'data'], []);
};

export const selectFavorites = state => {
    return state.data.favorites;
};
