// Modules
import { connect } from 'react-redux';

// Components
import MobileCategory from '../components/MobileCategory';

// Constants
import { openModal } from '../../home/modules/actionCreators';

// Utils
import { disableBodyScroll } from '../../../core/utils';

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    openModal: modalId => {
        dispatch(openModal(modalId));
        disableBodyScroll();
    },
    onNavigate: ({ url }) => {
        // ownProps.history.push(url);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileCategory);
