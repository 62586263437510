//import React from 'react';

const redirects = [
    { from: '/seating/dining-chairs/sale-promo', to: '/sale' },

    // 11-08-2021
    // - Changed asseccories categroy to other
    // - Removed Art subcategory
    // - Added Outdoor subcategory
    // - Changed other subcategory into miscellaneous
    { from: '/accessories', to: '/other' },
    { from: '/accessories/coatracks', to: '/other/coatracks' },
    { from: '/accessories/kids', to: '/other/kids' },
    { from: '/accessories/magazine-stands', to: '/other/magazine-stands' },
    { from: '/accessories/mirrors', to: '/other/mirrors' },
    { from: '/accessories/other', to: '/other/miscellaneous' },

];

export default redirects;
