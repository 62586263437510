// Modules
import React from 'react';
import axios from 'axios';

import { isFunction } from 'lodash';

// Styling
import './ZGAOForm.css';
import favorites from '../../core/favorites-storage';

class ZGAOForm extends React.PureComponent {
    requestComplete = response => {
        // this.setState({ spinner: false, formComplete: true });
    };
    handleSubmit = e => {
        const { requestComplete } = this;
        e.preventDefault();

        const formData = new FormData();
        const { fields, onLoading, onSuccess, onError } = this.props;

        for (let index = 0; index < fields.length; index++) {
            formData.set(fields[index], e.target[fields[index]].value);

            if (fields[index] === 'Favorites') {
                const allFavorites = favorites.getFavorites();
                const formattedForForm = allFavorites.map(item => item.url);
                formData.set(fields[index], JSON.stringify(formattedForForm));
            }
        }

        let els = document.querySelectorAll('input[type=file]');

        if (els[0]) {
            // Make sure the file has content before append it to the form object.
            let file = els[0];

            if (file.files.length > 0) {
                let fileData = file.files[0];

                // Only process image files.
                if (!fileData.type.match('image.*')) {
                    throw Error('Wrong File!');
                }

                formData.append('Foto1', fileData);
            }
        }

        // this.setState({ spinner: true });

        isFunction(onLoading) && onLoading();

        // Bind this to that so that Promise can set state
        axios({
            method: 'post',
            url: this.props.url,
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
        })
            .then(function(response) {
                //handle success
                isFunction(onSuccess) && onSuccess();
                return JSON.stringify(response);
            })
            .catch(function(response) {
                //handle error
                isFunction(onError) && onError();
                return JSON.stringify(response);
            })
            .then(requestComplete);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit} method="post" encType={'multipart/form-data'}>
                {this.props.children}
            </form>
        );
    }
}

export default ZGAOForm;
