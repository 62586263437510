// Modules
import React from 'react';
import i18n from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { isNil } from 'lodash';

// Components
import ShopLatestProducts from '../@shared/components/ShopLatestProducts';
import MadeBy from './MadeBy';

// Assets
import logoFooter from '../assets/zo-goed-als-oud-online-shop-for-vintage-furniture-lighting-and-accessories.svg';

// Styling
import './Footer.css';

const Footer = props => {
    let { recentlyViewed } = props;

    if (isNil(recentlyViewed)) {
        return <div>getting the recently viewed..</div>;
    }

    // Do localStorage stuff
    const currentStorage = localStorage.getItem('recently-viewed');
    if (!isNil(currentStorage)) {
        recentlyViewed = JSON.parse(currentStorage);
    }

    return (
        <div className="footer">
            <div className="page-content home-grey" style={{ marginBottom: '0' }}>
                <div className="content-block recently-viewed-container">
                    <h2 className="shop-latest recently-viewed">{i18n.translate('recentlyViewed')}</h2>
                    <ShopLatestProducts items={recentlyViewed.filter((item, index) => index < 4)} seeMoreItems={false} onNavigate={props.onNavigate} />
                </div>
            </div>

            <Disclaimer />
            <MadeBy />
        </div>
    );
};

const Disclaimer = props => (
    <div className="disclaimer-container">
        <div className="disclaimer">
            <div className="content-block">
                <div className="disclaimer-item" style={{ display: 'flex' }}>
                    <NavLink to={'/'}>
                        <div>
                            <img
                                src={logoFooter}
                                style={{ width: '200px' }}
                                alt="Buy vintage design furniture, lighting and accessories online at Zo Goed Als Oud online shop for 20th century items"
                            />
                        </div>
                    </NavLink>
                    <a
                        href="https://www.google.com/maps/place/As+Good+As+Old+%7C+Vintage+Design+Amsterdam/@52.286148,4.7810923,17z/data=!3m1!4b1!4m5!3m4!1s0x47c5e6aefafd67cd:0xc28d298b47ae3aa!8m2!3d52.286148!4d4.783281"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Visit our store in Oudemeer the Netherlands by appointment"
                    >
                        <div className={'address-section'}>
                            <b>Store Address</b>
                            <br />
                            (Open by appointment only)
                            <br />
                            Aalsmeerderdijk 70 sous
                            <br />
                            1438 AT Oude Meer
                            <br />
                            The Netherlands
                            <br />
                        </div>
                    </a>
                </div>
            </div>

            <div className="content-block instagram-container">
                <div className="disclaimer-item ">
                    <b>Follow us on Instagram</b>
                </div>

                <a className={'insta-link'} href="https://www.instagram.com/zogoedalsoud/" target={'_blank'} rel="noopener" aria-label="Follow us on Instagram">
                    <i style={{ fontSize: '50px', paddingTop: '20px' }} className="fab fa-instagram" />
                </a>
            </div>

            <div className="content-block">
                <div className="disclaimer-item">
                    <b>Contact</b> <br />
                    <a href={'tel:+31625218612'} aria-label="Call us">
                        +31 6 25218612
                    </a>
                    <br />
                    <a href={'mailto:contact@zogoedalsoud.com'} aria-label="Email us">
                        contact@zogoedalsoud.com
                    </a>
                    <br />
                    <span>CoC: 51863480</span>
                    <br />
                    <span>VAT: NL002088577B25</span>
                    <br />
                    <span>EORI: NL5305278811</span>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
