// Modules
import React from 'react';
import cx from 'classnames';

// Assets
import cross from '../../assets/kruisje.svg';
import hamburger from '../../assets/hamburgermenu.svg';

// Utils
import { menu } from './menu';
import { disableBodyScroll, enableBodyScroll, fadeInFrameworkContent, fadeOutFrameworkContent } from '../utils';
import { LinkComponent } from '../../@shared/components/LinkComponent';

export default class Hamburger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            submenuOpen: '',
            searchInput: '',
        };
    }

    onClickMenu = () => {
        if (!this.state.menuOpen) {
            disableBodyScroll();
            fadeInFrameworkContent();
        } else {
            enableBodyScroll();
            fadeOutFrameworkContent();
        }

        this.setState({ menuOpen: !this.state.menuOpen });
    };

    onClickSubMenu = categoryId => {
        const newStateValue = this.state.submenuOpen === categoryId ? undefined : categoryId;
        this.setState({ submenuOpen: newStateValue });
    };

    onSearchClick = () => {
        if (this.state.searchInput === '') return;
        if (this.state.searchInput === 'sale') {
            this.props.onNavigate({ url: '/sale' });
            return;
        }
        this.props.onNavigate({ url: `/search?result=${this.state.searchInput}`, search: this.state.searchInput });
    };

    // 13 is code for 'ENTER'
    checkEnterPressed = e => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            this.onSearchClick();
        }
    };

    render() {
        const { menuOpen } = this.state;
        const { onNavigate, openModal } = this.props;

        const icon = menuOpen ? cross : hamburger;

        return (
            <>
                <div className={'hamburger-container'} onClick={this.onClickMenu}>
                    <img src={icon} alt="Mobile navigation menu" />
                </div>

                <div className={cx('mobile-navigation-menu', { open: menuOpen, closed: !menuOpen })}>
                    <div className={'search-input-container--mobile'} aria-label={'searchbar'}>
                        <input
                            placeholder={'Search our collection...'}
                            value={this.state.searchInput}
                            onKeyUp={this.checkEnterPressed}
                            onChange={e => this.setState({ searchInput: e.target.value })}
                        />
                        <button type={'button'} onClick={this.onSearchClick} aria-label={'search'}>
                            <i className={'fa fa-search'} />
                        </button>
                    </div>

                    <ul>
                        {menu.map(({ modal, label, modalId, submenu, url, categoryId }) => {
                            return (
                                <li key={label}>
                                    {submenu && (
                                        <p onClick={() => this.onClickSubMenu(categoryId)}>
                                            {' '}
                                            <i
                                                className={cx('fa', {
                                                    'fa-caret-down': this.state.submenuOpen === categoryId,
                                                    'fa-caret-right': this.state.submenuOpen !== categoryId,
                                                })}
                                            />
                                            {label}{' '}
                                        </p>
                                    )}

                                    {modal && (
                                        <p
                                            onClick={() => {
                                                openModal(modalId);
                                            }}
                                        >
                                            {label}
                                        </p>
                                    )}

                                    {!submenu && !modal && (
                                        <LinkComponent
                                            url={url}
                                            onClick={() => {
                                                onNavigate({ url, categoryId });
                                            }}
                                            label={label}
                                        />
                                    )}

                                    {submenu && this.state.submenuOpen === categoryId && (
                                        <div className={'submenu-item'}>
                                            {submenu.map(item => (
                                                <div key={item.label}>
                                                    <LinkComponent url={item.url} onClick={() => onNavigate({ url: item.url, categoryId: item.categoryId })}>
                                                        {item.label}
                                                    </LinkComponent>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>

                    <div className={'contact-question'}>
                        <div className="contact-buttons">
                            <i className="fas fa-envelope" onClick={() => window.open('mailto:contact@zogoedalsoud.com')} />
                            <i className="fa fa-phone" onClick={() => window.open('tel:+31625218612')} />
                            <i className="fab fa-whatsapp" onClick={() => window.open(`https://wa.me/31625218612`)} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
